import {
  Route,
  Routes,
  Navigate,
  BrowserRouter
} from "react-router-dom";

//css
import "./scss/index.scss";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "react-spring-bottom-sheet/dist/style.css";

import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import "./scss/components/_one-signal.scss";
import "react-tooltip/dist/react-tooltip.css";
import "react-toastify/dist/ReactToastify.css";

import 'react-modern-drawer/dist/index.css'
import 'react-range-slider-input/dist/style.css';

import MainLayout from "./components/layout/main.layout";
import AboutUs from "./pages/about_us";
import Home from "./pages/home";
import Service from "./pages/service";
import Cart from "./pages/cart";
import ProfileCommission from "./pages/profile_commission";
import Order from "./pages/order";
import httpService from "./services/http.service";
import { useToast } from "./components/common/common.toast";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

function App() {
  const dispatch = useDispatch()
  const toast = useToast()

  useEffect(() => {
    httpService.initializeInterceptors(toast, dispatch);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <MainLayout>
              <Home />
            </MainLayout>
          }
        />
        <Route
          path="/service/:service"
          element={
            <MainLayout>
              <Service />
            </MainLayout>
          }
        />
        <Route
          path="/about-us"
          element={
            <MainLayout>
              <AboutUs />
            </MainLayout>
          }
        />
        <Route
          path="/cart"
          element={
            <MainLayout>
              <Cart />
            </MainLayout>
          }
        />
        <Route
          path="/profile"
          element={
            <MainLayout>
              <ProfileCommission />
            </MainLayout>
          }
        />
        <Route
          path="/commission"
          element={
            <MainLayout>
              <ProfileCommission />
            </MainLayout>
          }
        />
        <Route
          path="/order"
          element={
            <MainLayout>
              <Order />
            </MainLayout>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
