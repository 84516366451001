import { LazyLoadImage } from "react-lazy-load-image-component";
import Input from "../element/input";
import { Form, Formik } from "formik";
import { ClaimWindscreen, InsuranceRenew } from "../../assets/images";
import Button from "../element/button";
import useWindowDimensions from "../hook/use.window.dimensions";
import * as Yup from 'yup'
import { claimWindscreen, renewInsurance } from "../../redux/reducer/serviceReducer";
import { useDispatch, useSelector } from "react-redux";
import { openLoginModal } from "../../redux/reducer/modalReducer";
import { useToast } from "./common.toast";

export default function ImageWithForm({ locateAt }) {
    const toast = useToast()
    const dispatch = useDispatch()
    const { width } = useWindowDimensions()

    const { submitLoading } = useSelector((state) => state.service);  
    const { token, username } = useSelector((state) => state.common);  

    const schema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
        contact_number: Yup.string()
            .matches(/^[1-9]\d{7,9}$/, 'Please enter a valid phone number')
            .required('Contact no is required'),
        upload_file: Yup.mixed()
            .required('At least one(1) policy attachment is required')
            .test('fileFormat', 'Unsupported Format', value => value && ['image/jpeg', 'image/png', 'image/webp', 'application/pdf'].includes(value.type))
    });

    const imageWithForm = {
        'claim-windscreen': {
            title: 'Claim Windscreen',
            image: ClaimWindscreen,
            create: claimWindscreen,
            initial_form: {
                name: '',
                code: '+60',
                contact_number: '',
                upload_file: null
            },
            schema: schema,
            form_list: [
                {
                    type: 'input',
                    placeholder: 'Name',
                    name: 'name' 
                },
                {
                    type: 'input',
                    placeholder: 'Contact No.',
                    input_type: 'number',
                    name: 'contact_number' 
                },
                {
                    type: 'file-upload',
                    label: 'Your Insurance Policy',
                    name: 'upload_file' 
                }
            ],
            phone_no: '016-297 1664',
            redirect_no: '60162971664'
        },
        'insurance-renewal': {
            title: 'Insurance/Roadtax Renewal',
            image: InsuranceRenew,
            create: renewInsurance,
            initial_form: {
                name: '',
                code: '+60',
                contact_number: '',
                upload_file: []
            },
            schema: schema,
            form_list: [
                {
                    type: 'input',
                    placeholder: 'Name',
                    name: 'name' 
                },
                {
                    type: 'input',
                    placeholder: 'Contact No.',
                    input_type: 'number',
                    name: 'contact_number' 
                },
                {
                    type: 'file-upload',
                    label: 'Insurance Policy / Register Card',
                    name: 'upload_file' 
                }
            ],
            phone_no: '010-229 1664',
            redirect_no: '60102291664'
        }
    }

    const StepBar = () => {
        return (
            <article className="step-section mb-5">
                <hr className="step-line" />
                <section className="step --arrange">
                    <article className="step-bg">
                        <h6>1</h6>
                    </article>
                    <p>Arrange</p>
                </section>
                <section className="step --claim">
                    <article className="step-bg">
                        <h6>2</h6>
                    </article>
                    <p>Claim</p>
                </section>
                <section className="step --done">
                    <article className="step-bg">
                        <h6>3</h6>
                    </article>
                    <p>Done</p>
                </section>
            </article>
        )
    }

    const handleSubmit = (values, setFieldError) => {
        if(token && username) {
            const formData = new FormData();
    
            formData.append('name', values.name);
            formData.append('contact_number', `+60${values.contact_number}`);
            formData.append('insurance_policy', values.upload_file);
    
            dispatch(imageWithForm[locateAt].create(formData))
            .unwrap()
            .then((res) => {
                if(locateAt === 'claim-windscreen') {
                    toast.success('Claim windscreen submitted successfully! Your application has been processed.')
                } else {
                    toast.success('Insurance renewal submitted successfully! Your application has been processed.')
                }
            })
            .catch((ex) => {
                if (ex && ex.response?.status === 422) {
                    const errors = ex.response.data.errors;
                    if (errors && Object.keys(errors).length > 0) {
                        Object.keys(errors).map((item, i) => {
                            setFieldError(item, errors[item]);
                        });
                    }
                }
            })
        } else {
            dispatch(openLoginModal())
        }
    }

    const handleRedirectWhatsapp = (phoneNo, locateAt) => {
        let message = '';

        if(locateAt === 'insurance-renewal') {
            message = "Hi, I have an enquiry about insurance renewal and would like some assistance.";
        } else {
            message = "Hi, I have an enquiry about windscreen claim and would like some assistance.";
        }

        const whatsappURL = `https://wa.me/`;

        window.open(whatsappURL + phoneNo + '?text=' + encodeURIComponent(message))
    }

    return (
        <section className="w-100 grid center" style={{ gap: '50px' }}>
          <article className="image-with-form">
            <section className={`form-section --${locateAt}`}>
                <h4>{imageWithForm[locateAt].title}</h4>
                <StepBar />
                <Formik
                    initialValues={imageWithForm[locateAt].initial_form}
                    validationSchema={imageWithForm[locateAt].schema}
                    onSubmit={(values, { setFieldError }) => {
                        handleSubmit(values, setFieldError);
                    }}
                >
                    {({ values, errors, isValid, setFieldValue }) => (
                        <Form className="formik-form">
                            {imageWithForm[locateAt].form_list.map((form, formIndex) => (
                                form.type === 'input' ? 
                                    <article className="flex flex-column gap-1">
                                        <section className={form.name === "contact_number" ? "flex" : ""}>
                                            {form.name === "contact_number" && 
                                                <Input 
                                                    readOnly
                                                    name="code"
                                                    placeholder="Code"
                                                    className={width > 770 ? "ps-3 pe-2 w-20" : " w-20"}
                                                />
                                            }
                                            <Input 
                                                name={form.name}
                                                type={form.input_type}
                                                placeholder={form.placeholder}
                                                className={`${form.name === "contact_number" ? "w-80" : ""} ${form.name === "contact_number" ? width > 770 ? "ps-3 pe-3" : "ps-3" : width > 770 ? "ps-3 pe-3" : ""}`}
                                                errorClassName={width > 770 ? "ps-4 mt-2" : "ps-2 mt-2"}
                                                showError={form.name !== "contact_number"}
                                            /> 
                                        </section>
                                        {form.name === "contact_number" && <p className={`${width < 770 ? 'ps-2' : 'ps-4'} mt-2 element _errors text-right no-padding error-message`}>{errors[form.name]}</p>}
                                    </article>
                                : 
                                    <article className="mt-3">
                                        <h6>{form.label}</h6>
                                        <section className="flex items-center gap-4 mt-3">
                                            <article className="file-upload pointer">
                                                <input type="file" id="upload_file" onChange={(e) => setFieldValue('upload_file', e.target.files[0])} />
                                                <p>Upload File</p>
                                            </article>
                                            <p className="--grey">{values[form.name]?.name ? values[form.name].name : ''}</p>
                                        </section>
                                    </article>
                            ))}
                            <Button type="submit" disabled={!isValid || submitLoading} className="w-100 grid center" btnClassName="submit-button">Submit</Button>
                            <p className="enquiry-message" onClick={() => handleRedirectWhatsapp(imageWithForm[locateAt].redirect_no, locateAt)}>Call or whatsapp us for enquiries at&nbsp; <span>{imageWithForm[locateAt].phone_no}</span></p>
                        </Form>
                    )}
                </Formik>
            </section>
            <section className="form-frame">
                <LazyLoadImage src={imageWithForm[locateAt].image} />
            </section>
          </article>
        </section>
    );
}