import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AdvancePackage,
  Appearance,
  BasicPackage,
  Bubbling,
  Cracking,
  DoorHandles,
  DoorPillars,
  Durability,
  FrontBumper,
  FrontFender,
  FrontHood,
  FullFront,
  FullRoof,
  FullWrap,
  GoldPackage,
  Hazing,
  HeadLamps,
  Heating,
  Maintenance,
  Peeling,
  PlatinumPackage,
  Protection,
  RearBootOrBumper,
  RearFender,
  SampleFile,
  SampleProduct1,
  SampleProduct2,
  SampleTinted,
  SampleWrapping,
  SideMirrors,
  SilverPackage,
  Staining,
} from "../../assets/images";
import apiService from "../../services/api.service";

// export const getProductList = createAsyncThunk('getProductList', async (payload, thunkAPI) => {
//   try {
//     const response = await apiService.getProductList(payload);
//     return response;
//   } catch (error) {
//     return thunkAPI.rejectWithValue(error);
//   }}
// );

let currentAbortController = null;

export const getProductList = createAsyncThunk(
  "getProductList",
  async (payload, thunkAPI) => {
    try {
      if (currentAbortController && !currentAbortController.signal.aborted) {
        currentAbortController.abort();
      }

      currentAbortController = new AbortController();
      const signal = currentAbortController.signal;

      const response = await apiService.getProductList(payload, { signal });

      currentAbortController = null;

      return response;
    } catch (error) {
      if (error.name === "AbortError") {
      } else {
        currentAbortController = null;
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getProductInfo = createAsyncThunk(
  "getProductInfo",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getProductInfo(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const claimWindscreen = createAsyncThunk(
  "claimWindscreen",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.claimWindscreen(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const renewInsurance = createAsyncThunk(
  "renewInsurance",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.renewInsurance(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const initialData = {
  //tinted
  selectedTintedSeries: "jt_series",
  selectedTintedType: "solar",
  // selectedTintedBodyType: 'standard',
  selectedTintedPackage: {},
  tintedSeriesList: {
    "JT Series": [
      {
        series_name: "JT Series",
        series_value: "jt_series",
        type_name: "Solar",
        type_value: "solar",
        file_name: "JT Solar Series",
        file_image: "",
      },
      {
        series_name: "JT Series",
        series_value: "jt_series",
        type_name: "Security",
        type_value: "security",
        file_name: "JT Security Series",
        file_image: "",
      },
    ],
    "Solar Gard": [
      {
        series_name: "Solar Gard",
        series_value: "solar_gard",
        type_name: "LX Series",
        type_value: "lx_series",
        file_name: "Solar Gard LX Series",
        file_image: "",
      },
      {
        series_name: "Solar Gard",
        series_value: "solar_gard",
        type_name: "Black Phantom",
        type_value: "black_phantom",
        file_name: "Solar Gard BP Series",
        file_image: "",
      },
    ],
  },
  tintedPackageList: {},
  tintedAlacarteList: {},

  //ppf
  warrantyList: [
    {
      label: "Cracking",
      image: Cracking,
    },
    {
      label: "Bubbling",
      image: Bubbling,
    },
    {
      label: "Peeling",
      image: Peeling,
    },
    {
      label: "Hazing",
      image: Hazing,
    },
    {
      label: "Staining",
      image: Staining,
    },
  ],
  ppfPackageList: {},
  ppfAlacarteList: {},

  //wrapping
  wrappingList: [
    {
      label: "Protection",
      image: Protection,
      description:
        "Colored TPU PPF film helps Protect the car's surface from stone chips, scratches, and other environmental damages",
    },
    {
      label: "Appearance",
      image: Appearance,
      description:
        "Colored TPU PPF film helps Protect the car's surface from stone chips, scratches, and other environmental damages",
    },
    {
      label: "Self-heating",
      image: Heating,
      description:
        "Colored TPU PPF film helps Protect the car's surface from stone chips, scratches, and other environmental damages",
    },
    {
      label: "Durability",
      image: Durability,
      description:
        "Colored TPU PPF film helps Protect the car's surface from stone chips, scratches, and other environmental damages",
    },
    {
      label: "Easy maintenance",
      image: Maintenance,
      description:
        "Colored TPU PPF film helps Protect the car's surface from stone chips, scratches, and other environmental damages",
    },
  ],
  wrappingPackageList: {},
  wrappingCompareList: [
    {
      title: "Others color changing films",
      compare_detail: [
        {
          image: SampleWrapping,
          description:
            "It is easy to drop glue and damage the original car paint",
        },
        {
          image: SampleWrapping,
          description: "Inferior film is east to delaminate and shrink edges",
        },
        {
          image: SampleWrapping,
          description: "Easy to Splicing Bubbles !",
        },
      ],
      value: "others",
    },
    {
      title: "Our Color Changing Films",
      compare_detail: [
        {
          image: SampleWrapping,
          description: "No Glue / No Damage to the Original Car Paint",
          minHeight: window.innerWidth < 480 ? true : false,
        },
        {
          image: SampleWrapping,
          description: "No Delamination, No Edge Contraction",
          minHeight: true,
        },
        {
          image: SampleWrapping,
          description: "Splicing Without Bubbles",
        },
      ],
      value: "ours",
    },
  ],

  //coating
  coatingAlacarteTable: [
    {
      title: "Type of Coating",
      value: "type",
    },
    {
      title: "Standard",
      value: "standard",
    },
    {
      title: "SUV",
      value: "suv",
    },
    {
      title: "MPV",
      value: "mpv",
    },
  ],
  coatingPackageList: {},
  coatingAlacarteList: {},

  //accessories
  accessoriesList: [],
  accessoriesInfo: {},

  productLoading: false,
  productInfoLoading: false,
  submitLoading: false,
};

function groupTintedProducts(products, type) {
  const grouped = {};

  const filteredProducts = products.filter(
    (prod) => prod.is_package.toString() === type.toString()
  );

  filteredProducts.forEach((prod) => {
    const series = prod.series?.category?.toLowerCase().replace(/\s+/g, "_");
    const seriesName = prod.series?.name?.toLowerCase().replace(/\s+/g, "_");
    const bodyType = prod.category?.name?.toLowerCase().replace(/\s+/g, "_");

    if (!grouped[series]) {
      grouped[series] = {};
    }
    if (!grouped[series][seriesName]) {
      grouped[series][seriesName] = {
        standard: [],
        luxury: [],
        suv: [],
        mpv: [],
      };
    }
    if (grouped[series][seriesName][bodyType] === undefined) {
      grouped[series][seriesName][bodyType] = [];
    }
    grouped[series][seriesName][bodyType].push(prod);
  });

  return grouped;
}

function groupProducts(products, type) {
  const grouped = {};

  const filteredProducts = products.filter(
    (prod) => prod.is_package.toString() === type.toString()
  );

  filteredProducts.forEach((prod) => {
    const bodyType = prod.category?.name.toLowerCase().replace(/\s+/g, "_");

    if (!grouped[bodyType]) {
      grouped[bodyType] = [];
    }

    grouped[bodyType].push(prod);
  });

  return grouped;
}

function groupProductsName(products, type) {
  const grouped = {};

  const filteredProducts = products.filter(
    (prod) => prod.is_package.toString() === type.toString()
  );

  filteredProducts.forEach((prod) => {
    const name = prod.name.name;
    const bodyType = prod.category?.name?.toLowerCase().replace(/\s+/g, "_");
    const idKey = bodyType + "_id";
    const priceKey = bodyType + "_price";
    const goldPriceKey = bodyType + "_gold_price";
    const silverPriceKey = bodyType + "_silver_price";
    const bronzePriceKey = bodyType + "_bronze_price";

    if (!grouped[name]) {
      grouped[name] = {
        name: name,
        standard_id: null,
        suv_id: null,
        mpv_id: null,
        standard_price: 0,
        suv_price: 0,
        mpv_price: 0,
        standard_gold_price: 0,
        standard_silver_price: 0,
        standard_bronze_price: 0,
        suv_gold_price: 0,
        suv_silver_price: 0,
        suv_bronze_price: 0,
        mpv_gold_price: 0,
        mpv_silver_price: 0,
        mpv_bronze_price: 0,
      };
    }

    grouped[name][idKey] = prod.id;

    grouped[name][priceKey] = prod.price;
    grouped[name][goldPriceKey] = prod.gold_price;
    grouped[name][silverPriceKey] = prod.silver_price;
    grouped[name][bronzePriceKey] = prod.bronze_price;
  });

  return Object.values(grouped);
}

const dataSlice = createSlice({
  name: "service",
  initialState: initialData,
  reducers: {
    setSelectedTintedSeries: (state, action) => {
      state.selectedTintedSeries = action.payload;
    },
    setSelectedTintedType: (state, action) => {
      state.selectedTintedType = action.payload;
    },
    // setSelectedTintedBodyType: (state, action) => {
    //   state.selectedTintedBodyType = action.payload;
    // },
    setSelectedTintedPackage: (state, action) => {
      state.selectedTintedPackage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductList.pending, (state) => {
        state.productLoading = true;
      })
      .addCase(getProductList.fulfilled, (state, action) => {
        state.productLoading = false;
        const pathname = window.location.pathname
          ?.split("/")[2]
          ?.replace(/-/g, " ");

        switch (pathname) {
          case "car tinted":
            state.tintedPackageList = groupTintedProducts(
              action.payload.data.packages,
              "true"
            );
            state.tintedAlacarteList = groupTintedProducts(
              action.payload.data.packages,
              "false"
            );
            break;
          case "car ppf":
            state.ppfPackageList = groupProducts(
              action.payload.data.packages,
              "true"
            );
            state.ppfAlacarteList = groupProducts(
              action.payload.data.packages,
              "false"
            );
            break;
          case "car wrapping":
            state.wrappingPackageList = groupProducts(
              action.payload.data.packages,
              "false"
            );
            break;
          case "car coating":
            state.coatingPackageList = groupProducts(
              action.payload.data.packages,
              "true"
            );
            state.coatingAlacarteList = groupProductsName(
              action.payload.data.packages,
              "false"
            );
            break;
          case "accessories":
            state.accessoriesList = action.payload.data.packages;
            break;
          default:
            state.accessoriesList = action.payload.data.packages;
            break;
        }
      })
      .addCase(getProductList.rejected, (state) => {
        state.productLoading = false;
      })

      .addCase(getProductInfo.pending, (state) => {
        state.productInfoLoading = true;
      })
      .addCase(getProductInfo.fulfilled, (state, action) => {
        state.productInfoLoading = false;
        state.accessoriesInfo = action.payload.data;
      })
      .addCase(getProductInfo.rejected, (state) => {
        state.productInfoLoading = false;
      })

      .addCase(claimWindscreen.pending, (state) => {
        state.submitLoading = true;
      })
      .addCase(claimWindscreen.fulfilled, (state, action) => {
        state.submitLoading = false;
      })
      .addCase(claimWindscreen.rejected, (state) => {
        state.submitLoading = false;
      })

      .addCase(renewInsurance.pending, (state) => {
        state.submitLoading = true;
      })
      .addCase(renewInsurance.fulfilled, (state, action) => {
        state.submitLoading = false;
      })
      .addCase(renewInsurance.rejected, (state) => {
        state.submitLoading = false;
      });
  },
});

export const {
  setSelectedTintedSeries,
  setSelectedTintedType,
  // setSelectedTintedBodyType,
  setSelectedTintedPackage,
} = dataSlice.actions;

export default dataSlice.reducer;
