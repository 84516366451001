import { Icon } from "@iconify/react";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import { useNavigate } from "react-router-dom";

export default function CartHeader() {
    const navigate = useNavigate()
    const { width } = useWindowDimensions()

    const tabelHeaderList = [
        {
            label: 'Product',
            sub_label: 'Product'
        }, 
        // {
        //     label: 'Unit Price',
        //     sub_label: 'Price'
        // }, 
        {
            label: 'Quantity',
            sub_label: 'Qty'
        }, 
        {
            label: 'Subtotal',
            sub_label: 'Subtotal'
        }, 
    ]

    const handleToOrder = () => {
        navigate('/order')
    }

    return (
        <section className="cart-header">
           <article className="header-section">
                <section className="flex items-baseline justify-between">
                    <h5>My Cart</h5>
                    <article className="flex items-center gap-2 pointer" onClick={() => handleToOrder()}>
                        <p>My Order</p>
                        <Icon icon="uil:clipboard-alt" />
                    </article>
                </section>
                <hr />
                <section className="header-list">
                    {tabelHeaderList.map((header, headerIndex) => (
                        <p key={headerIndex}>{width < 550 ? header.sub_label : header.label}</p>
                    ))}
                </section>
            </article>
        </section>
    );
}
