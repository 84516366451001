import { createSlice } from "@reduxjs/toolkit";
import { SampleProduct1, SampleProduct2 } from "../../assets/images";

export const initialData = {
  productList: [
    {
      name: "Title",
      price: 500,
      promo_price: 550,
      image: SampleProduct1
    },
    {
      name: "Title",
      price: 500,
      promo_price: 550,
      image: SampleProduct2
    },
    {
      name: "Title",
      price: 500,
      promo_price: 550,
      image: SampleProduct1
    },
    {
      name: "Title",
      price: 500,
      promo_price: 550,
      image: SampleProduct2
    },
    {
      name: "Title",
      price: 500,
      promo_price: 550,
      image: SampleProduct1
    },
    {
      name: "Title",
      price: 500,
      promo_price: 550,
      image: SampleProduct2
    },
  ],
};

const dataSlice = createSlice({
  name: "home",
  initialState: initialData,
  reducers: {
  },
  extraReducers: {
  },
});

export const { 
} = dataSlice.actions;

export default dataSlice.reducer;

