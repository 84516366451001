import { Icon } from "@iconify/react/dist/iconify.js";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { motion,useInView } from 'framer-motion';
import { useRef } from "react";
import { setSelectedPackageOrAlacarte } from "../../redux/reducer/commonReducer";

export default function WeSpecializeIn() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { pathname } = useLocation(); 
    const { specializeList } = useSelector((state) => state.common);

    const serviceListRef = useRef(null);
    const isInView = useInView(serviceListRef, { once: true, amount: 0.1 });

    // const filteredSpecializeList = pathname === '/' 
    //     ? specializeList.filter(service => service.label !== 'Accessories')
    //     : specializeList;

    const handleNavTo = (page) => {
        navigate(page)
        dispatch(setSelectedPackageOrAlacarte('alacarte'))
    }

    return (
        <article className="specialize-in">
            <h3 className="uppercase">We Specializes in</h3>
            <motion.section 
                // ref={serviceListRef}
                // initial="hidden"
                // animate={isInView ? "visible" : "hidden"}
                // variants={{
                //     hidden: {},
                //     visible: {
                //         transition: {
                //         staggerChildren: 0.2,
                //         },
                //     },
                // }}
                className="service-list" 
                style={{ gap: "2em" }}
            >
                {specializeList.map((service, serviceIndex) => (
                    <motion.article 
                        className={`service-item ${pathname === service.navigate ? '--active' : ''} pointer`} 
                        onClick={() => handleNavTo(service.navigate)}
                        key={serviceIndex}
                        // variants={{
                        //     hidden: { opacity: 0, y: 20 },
                        //     visible: { opacity: 1, y: 0 },
                        // }}
                        // whileHover={{ scale: 1.05 }}
                        // whileTap={{ scale: 0.95 }}
                    >
                        {/* <section className="flex flex-column gap-8px"> */}
                        <article>
                            <Icon icon={pathname === service.navigate ? service.active_icon ?? service.icon : service.icon} />
                        </article>
                        <p>{service.label}</p>
                        {/* </section> */}
                        {/* <span class="shape">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </span> */}
                    </motion.article>
                ))}
            </motion.section>
        </article>
    );
}