import { useEffect } from "react";
import ImageWithForm from "../../components/common/image.with.form";
import scrollToTop from "../../helpers/scroll.to.top";

export default function InsuranceRenewal() {
  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <article className="insurance-renewal-page w-100 grid center" style={{ gap: '50px' }}>
      <ImageWithForm locateAt='insurance-renewal'/>
    </article>
  );
}
