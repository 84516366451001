import { useDispatch, useSelector } from "react-redux";
import { closeEditPriceModal } from "../../../redux/reducer/modalReducer";
import ModalHeader from "../../../components/header/modal.header";
import FullModal from "../../../components/modal/full.modal.box";
import { MDBModalBody, MDBModalFooter } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import {
  getCartInfo,
  updateCart,
} from "../../../redux/reducer/cartOrderReducer";
import { useToast } from "../../../components/common/common.toast";

export default function EditPriceModal() {
  const toast = useToast();
  const dispatch = useDispatch();
  const editPriceRef = useRef(null);
  const { isOpenEditPriceModal } = useSelector((state) => state.modal);
  const { selectedCartItem, updateCartLoading } = useSelector(
    (state) => state.cart_order
  );

  const [isFocusInput, setIsFocusInput] = useState(false);
  const [markupPrice, setMarkupPrice] = useState(selectedCartItem.price);

  const priceDetails = [
    {
      label: "Normal Selling Price",
      value: selectedCartItem?.price,
      value_class: "",
    },
    {
      label: "Your Selling Price",
      value: markupPrice,
      value_class: "--blue",
    },
    {
      label: "Agent Price",
      value: selectedCartItem?.agent_price,
      value_class: "--blue",
    },
    {
      label: "Margin",
      value: markupPrice - selectedCartItem?.agent_price,
      value_class: "--red",
    },
  ];

  const handleCloseModal = () => {
    dispatch(closeEditPriceModal());
  };

  const handleClickOutside = (event) => {
    if (editPriceRef.current && !editPriceRef.current.contains(event.target)) {
      setIsFocusInput(false);
    }
  };

  const handleUpdateCart = () => {
    if (parseFloat(markupPrice) >= parseFloat(selectedCartItem.agent_price)) {
      dispatch(
        updateCart({
          id: selectedCartItem.id,
          quantity: selectedCartItem.quantity,
          note: selectedCartItem.note,
          markup_price: parseFloat(markupPrice).toFixed(2),
        })
      )
        .unwrap()
        .then((res) => {
          dispatch(getCartInfo());
          dispatch(closeEditPriceModal());
          toast.success("Markup the price of selected cart item successfully");
        })
        .catch((ex) => {
          if (ex && ex.response?.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                toast.error(errors[item][0]);
              });
            }
          }
        });
    } else {
      toast.error("Markup price should equal or exceeds the agent price");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <FullModal
      // centered
      staticBackdrop={true}
      show={isOpenEditPriceModal}
      backButton={handleCloseModal}
      screenSize="xl"
      className="overflow-hidden"
      contentClassName="br-34"
      content={
        <>
          <ModalHeader
            className="br-34"
            backTo={handleCloseModal}
            shareTo=""
            title="Edit Selling Price"
          />
          <MDBModalBody>
            <article className="edit-price-modal">
              <section className="w-100 grid center">
                <article
                  ref={editPriceRef}
                  className={`edit-price ${isFocusInput ? "--focus" : ""}`}
                  onClick={() => setIsFocusInput(true)}
                >
                  {isFocusInput ? (
                    <>
                      <h4>RM </h4>
                      <input
                        value={markupPrice}
                        autoFocus
                        onChange={(e) => setMarkupPrice(e.target.value)}
                      />
                      <Icon icon="lucide:edit" />
                    </>
                  ) : (
                    <>
                      <h4>RM {markupPrice}</h4>
                      <Icon icon="lucide:edit" />
                    </>
                  )}
                </article>
              </section>
              <section>
                <article className="price-detail">
                  <h6 className="--title mb-2">Price Details</h6>
                  {priceDetails.map((price, priceIndex) => (
                    <article className="price-row" key={priceIndex}>
                      <h6>{price.label}</h6>
                      <p className={price.value_class}>{price.value}</p>
                    </article>
                  ))}
                </article>
              </section>
            </article>
          </MDBModalBody>
          <MDBModalFooter
            className="p-0"
            onClick={() => {
              if (!updateCartLoading) {
                handleUpdateCart();
              }
            }}
          >
            <article
              className={`footer-button ${
                markupPrice === 0 ? "--disabled" : ""
              }`}
            >
              <h5>Update Cart</h5>
            </article>
          </MDBModalFooter>
        </>
      }
    />
  );
}
