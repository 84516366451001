import { useState } from "react";

export default function DocumentRequired() {
    const [type, setType] = useState('private')

    const documentRequired = {
        'private': {
            title: 'PRIVATE CAR (KERETA PENSENDIRIAN)',
            value: 'private',
            content: [
                'IC dan Driving License',
                'Cover Note or Insurance Policy',
                'Vehicle Registration Card (Geran Kereta)'
            ]
        },
        'commercial': {
            title: 'COMMERCIAL CAR (KERETA SYARIKAT)',
            content: [
                'IC dan Driving License (Driver)',
                'Cover Note or Insurance Policy',
                'Vehicle Registration Card (Geran Kereta)',
                'Business Registration (Front Page Only)',
                'Company Chop'
            ]
        }
    }

    return (
      <article className="document-required">
        <h3>Below are the <span>documents required</span> to submit your claim</h3>
        <section className="car-type">
            {Object.keys(documentRequired).map((key, documentIndex) => (
                <article className={`type-item ${key === type ? '--active' : ''}`} key={documentIndex} onClick={() => setType(key)}>
                    <h6>{documentRequired[key].title}</h6>
                </article>
            ))}
        </section>
        <ol className="document-content">
            {documentRequired[type].content.length > 0 && documentRequired[type].content.map((content, contentIndex) => (
                <li key={contentIndex}>&nbsp;{content}</li>
            ))}
        </ol>
      </article>
    );
}
  