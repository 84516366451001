import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from "mdb-react-ui-kit";
import { RemoveScroll } from "react-remove-scroll";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAnimationModal,
  setShowModal,
} from "../../redux/reducer/modalReducer";

export default function FullModal({
  show,
  setShow,
  content,
  centered,
  screenSize,
  scrollable,
  className,
  backButton,
  bodyClassName,
  contentClassName,
  dialogClassName,
  modalRef,
}) {
  const dispatch = useDispatch();
  const { showModal, isAnimationModal } = useSelector((state) => state.modal);

  const handleCloseModalWithDelay = () => {
    dispatch(setShowModal(false));
    setTimeout(() => {
      dispatch(setAnimationModal(true));
      backButton();
    }, 500);
  };

  useEffect(() => {
    dispatch(setShowModal(show));
  }, [show]);

  return (
    <RemoveScroll>
      <AnimatePresence>
        {showModal && (
          <MDBModal
            className={`full-modal-box-container ${className ?? ""}`}
            tabIndex="-1"
            show={true}
            onClick={() => handleCloseModalWithDelay()}
          >
            <motion.div
              initial={
                isAnimationModal && {
                  scale: 0.2,
                }
              }
              animate={
                isAnimationModal && {
                  scale: 1,
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                  },
                }
              }
              exit={
                isAnimationModal && {
                  opacity: [1, 0],
                  scale: [1, 0.8],
                  transition: {
                    duration: 0.3,
                  },
                }
              }
            >
              <MDBModalDialog
                centered={centered}
                size={`${screenSize}`}
                className={dialogClassName}
                scrollable={scrollable ? true : false}
                onClick={(e) => e.stopPropagation()}
              >
                <MDBModalContent className={contentClassName}>
                  <motion.div
                    initial={
                      isAnimationModal && {
                        opacity: 0,
                      }
                    }
                    animate={
                      isAnimationModal && {
                        opacity: 1,
                        transition: {
                          delay: 0.3,
                          duration: 0.3,
                        },
                      }
                    }
                    exit={
                      isAnimationModal && {
                        opacity: 0,
                        transition: {
                          duration: 0.3,
                        },
                      }
                    }
                  >
                    {content}
                  </motion.div>
                </MDBModalContent>
              </MDBModalDialog>
            </motion.div>
          </MDBModal>
        )}
      </AnimatePresence>
    </RemoveScroll>
  );
}
