import { Icon } from "@iconify/react";

export default function DownloadTab({ title, className }) {
    return (
        <article className={`download-file ${className} pointer`}>
            <p>{title}</p>
            <article className="download-icon">
                <Icon icon="line-md:download-loop" />
            </article>
        </article>
    );
}