import { LazyLoadImage } from "react-lazy-load-image-component";
import { Swiper, SwiperSlide } from "swiper/react";
import { CeramicCoating, InsuranceRenewal, ProtectionFilm, SampleFile, VehicleWrapping, WindowTinting, WindscreenClaim } from "../../../assets/images";
import { Icon } from "@iconify/react";
import { useRef } from "react";
import { Navigation } from 'swiper/modules';

export default function OneStopSolution() {
    const prevRef = useRef(null);

    const oneStopList = [
        {
            image: WindowTinting,
            title: 'Window Tinting',
            subtitle: 'Enhance privacy and reduce heat with our top-grade window tints, customized to fit your style and needs.'
        },
        {
            image: ProtectionFilm,
            title: 'Paint Protection Film',
            subtitle: `Protect your car's paint from chips, scratches, and wear with our durable, invisible PPF applications.`
        },
        {
            image: CeramicCoating,
            title: 'Ceramic Coating',
            subtitle: `Shield your vehicle's surface with a high-gloss ceramic coating that guards against dirt, UV rays, and oxidation.`
        },
        {
            image: VehicleWrapping,
            title: 'Vehicle Wrapping',
            subtitle: `Transform your vehicle's appearance with custom vinyl wraps that offer protection and a bold new look.`
        },
        {
            image: WindscreenClaim,
            title: 'Windscreen Claims',
            subtitle: 'Get quick and easy windscreen replacements or repairs with our seamless insurance claim process.'
        },
        {
            image: InsuranceRenewal,
            title: 'Insurance Renewal',
            subtitle: `Let us handle your car insurance renewal, making sure you're always covered with minimal hassle.`
        }
    ] 

    return (
        <article className="w-100 flex flex-column items-center" style={{ gap: '50px' }}>
            <section className="one-stop-solution">
                <article className="one-stop-layout">
                    <p><span />Comprehensive Car Care</p>
                    <article className="one-stop-text">
                        <h2>Your One-Stop Car Care Solution</h2>
                        <p className="--subtitle">We offers a wide range of services to enhance and protect your vehicle. Our experienced team is dedicated to ensuring your car looks its best and remains in excellent condition, providing customized solutions for all your automotive care needs.</p>
                    </article>
                    <article className="one-stop-list">
                        <section>
                            <button ref={prevRef} className="previous-button">
                                <article className="left-arrow">
                                    <section className="arrow" />
                                    <section className="line" />
                                </article>
                            </button>
                        </section>
                        <section className="relative max-w-100">
                            <Swiper
                                className="one-stop-list"
                                slidesPerView="auto"
                                spaceBetween={30}
                                grabCursor={true}
                                rewind={true}
                                navigation={{
                                    prevEl: prevRef.current, // Reference the prev button
                                }}
                                onSwiper={(swiper) => {
                                    // Update swiper navigation buttons after initialization
                                    swiper.params.navigation.prevEl = prevRef.current;
                                    swiper.navigation.init();
                                    swiper.navigation.update();
                                }}
                                modules={[ Navigation ]}
                            >
                                {oneStopList.map((oneStop, oneStopIndex) => (
                                    <SwiperSlide className="one-stop-item" key={oneStopIndex}>
                                        <section className="one-stop-frame">
                                            <LazyLoadImage src={oneStop.image} />
                                        </section>
                                        <section className="one-stop-desc">
                                            <h4>{oneStop.title}</h4>
                                            <p>{oneStop.subtitle}</p>
                                        </section>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </section>
                    </article>
                </article>
            </section>
        </article>
    );
}