import { useLocation } from "react-router-dom";
import OrderList from "./components/order.list";
import scrollToTop from "../../helpers/scroll.to.top";
import { useEffect } from "react";

export default function Order() {
  const { pathname } = useLocation()

  useEffect(() => {
    scrollToTop()
  }, [])

  return (
      <section className="order-page page-padding">
        <OrderList locateAt={pathname.replace(/^\//, '')} />
      </section>
  );
}