import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";

export default function VisionMission() {
    const { visionMissionList } = useSelector((state) => state.common);

    return (
        <article className="w-100 flex flex-column items-center">
            <section className="vision-mission">
                {visionMissionList.map((visionMission, visionMissionIndex) => (
                    <article className="vision-mission-item-grid" key={visionMissionIndex}>
                        <section className="vision-mission-text">
                            <h5>
                                {visionMission.title}
                            </h5>
                            <p dangerouslySetInnerHTML={{ __html: visionMission.description }}></p>
                        </section>
                        <section className="vision-mission-frame">
                            <LazyLoadImage src={visionMission.image} />
                        </section>
                    </article>
                ))}
            </section>
        </article>
    );
}