import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ProfileCommissionForm from "./components/profile.commission.form";
import scrollToTop from "../../helpers/scroll.to.top";
import { useEffect } from "react";

export default function ProfileCommission() {
  const { pathname } = useLocation()
  const { role } = useSelector((state) => state.common);

  useEffect(() => {
    scrollToTop()
  }, [])

  return (
      ((role === 'user' && pathname === '/profile') || role === 'agent') &&
      <section className="profile-commission-page page-padding">
        <ProfileCommissionForm locateAt={pathname.replace(/^\//, '')} />
      </section>
  );
}