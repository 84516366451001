import { useState } from "react";
import { OnlyLogo, Sample } from "../../assets/images";
import { useEffect } from "react";
import { Lambo } from "../../assets/videos";

export default function Preloader() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [removePreload, setRemovePreload] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [isLoaded]);

  useEffect(() => {
    document.fonts.ready.then(() => {
      checkIfLoaded();
    });

    const image = new Image();
    image.src = OnlyLogo;
    image.onload = () => checkIfLoaded();

    // const video = document.createElement("video");
    // video.src = Lambo; // Replace with your video source
    // video.oncanplaythrough = () => checkIfLoaded(); // Fires when the video can start playing

    function checkIfLoaded() {
      if (image.complete && document.fonts.ready) {
        setIsLoaded(true);

        setTimeout(() => {
          setRemovePreload(true);
        }, 2000);
      }
    }
  }, []);

  if (!removePreload) {
    return (
      <article className="preloader">
        <section className="logo-frame">
          <img src={OnlyLogo} loading="eager" />
        </section>
        <section className="logo-anim-wrapper flex justify-center">
          <svg
            id="Logo_tvar"
            x="0px"
            y="0px"
            viewBox="0 0 260 109.1"
            style={{ enableBackground: "new 0 0 260 109.1" }}
            // xml:space="preserve"
          >
            <path
              class="logo-linka"
              d="M 85 13 L 79 15 L 74 21 L 75 29 L 80 32 L 86 34 L 91 34 L 95 38 L 92 42 L 83 41 Q 80 41 80 37 Q 79 34 75 35 Q 73 37 74 43 L 78 45 Q 83 44 85 46 L 93 46 L 100 44 L 104 38 Q 105 31 102 29 Q 96 24 85 24 Q 82 23 83 20 L 87 17 L 94 18 L 98 23 L 101 23 L 101 15 L 92 13 L 85 13 Z M 10 14 Q 8 20 15 18 L 15 48 L 14 49 L 14 50 L 10 50 L 9 46 L 5 46 L 5 53 L 9 54 Q 18 55 23 52 L 26 47 L 26 19 L 30 18 L 30 14 L 10 14 Z M 32 14 Q 30 20 36 18 L 36 37 L 38 42 L 43 45 L 48 46 L 56 46 L 63 44 L 67 39 L 67 19 L 72 18 L 72 16 L 71 14 L 58 14 L 58 18 L 62 19 L 62 38 Q 60 43 51 41 L 47 37 L 47 19 L 51 18 L 51 14 L 32 14 Z M 119 14 L 119 23 L 123 23 Q 125 22 124 18 L 131 18 L 131 19 L 131 41 Q 127 40 126 43 L 127 45 L 146 45 L 146 41 L 142 41 L 142 18 L 149 18 Q 147 26 154 23 L 154 15 L 154 14 L 119 14 Z M 155 14 Q 153 20 159 18 L 159 41 L 155 41 L 156 45 L 174 45 Q 176 39 170 41 L 170 19 L 174 18 L 174 14 L 155 14 Z M 177 14 L 177 18 L 181 19 L 181 41 L 177 43 L 178 45 L 190 45 L 190 41 L 186 41 L 186 27 L 206 45 L 213 45 L 213 19 L 217 18 L 217 14 L 204 14 Q 202 20 208 18 L 208 33 L 189 14 L 177 14 Z M 218 14 L 218 23 Q 226 26 223 18 L 230 18 L 230 41 L 226 41 L 227 45 L 246 45 L 245 41 L 241 41 L 241 18 L 249 18 L 249 23 Q 253 24 254 22 L 254 15 L 254 14 L 218 14 Z M 109 30 L 109 37 L 125 37 L 125 30 L 109 30 Z"
            />
          </svg>
          <svg
            // id="Logo_tvar"
            // width="260"
            // height="60"
            // version="1.1"
            // xmlns="http://www.w3.org/2000/svg"
            // desc="Created with imagetracer.js version 1.2.6"

            id="Logo_vypln"
            className={isLoaded ? "fade-in" : ""}
            x="0px"
            y="0px"
            viewBox="0 0 260 109.1"
            style={{ enableBackground: "new 0 0 260 109.1" }}
          >
            <path
              fill="rgb(0,0,0)"
              stroke="rgb(0,0,0)"
              stroke-width="1"
              opacity="0"
              d="M 0 0 L 259.5 0 L 260 0.5 L 260 60 L 0.5 60 L 0 59.5 L 0 0 Z M 85 13 L 79 15 L 74 21 L 75 29 L 80 32 L 86 34 L 91 34 L 95 38 L 92 42 L 83 41 Q 80 41 80 37 Q 79 34 75 35 Q 73 37 74 43 L 78 45 Q 83 44 85 46 L 93 46 L 100 44 L 104 38 Q 105 31 102 29 Q 96 24 85 24 Q 82 23 83 20 L 87 17 L 94 18 L 98 23 L 101 23 L 101 15 L 92 13 L 85 13 Z M 10 14 Q 8 20 15 18 L 15 48 L 14 49 L 14 50 L 10 50 L 9 46 L 5 46 L 5 53 L 9 54 Q 18 55 23 52 L 26 47 L 26 19 L 30 18 L 30 14 L 10 14 Z M 32 14 Q 30 20 36 18 L 36 37 L 38 42 L 43 45 L 48 46 L 56 46 L 63 44 L 67 39 L 67 19 L 72 18 L 72 16 L 71 14 L 58 14 L 58 18 L 62 19 L 62 38 Q 60 43 51 41 L 47 37 L 47 19 L 51 18 L 51 14 L 32 14 Z M 119 14 L 119 23 L 123 23 Q 125 22 124 18 L 131 18 L 131 19 L 131 41 Q 127 40 126 43 L 127 45 L 146 45 L 146 41 L 142 41 L 142 18 L 149 18 Q 147 26 154 23 L 154 15 L 154 14 L 119 14 Z M 155 14 Q 153 20 159 18 L 159 41 L 155 41 L 156 45 L 174 45 Q 176 39 170 41 L 170 19 L 174 18 L 174 14 L 155 14 Z M 177 14 L 177 18 L 181 19 L 181 41 L 177 43 L 178 45 L 190 45 L 190 41 L 186 41 L 186 27 L 206 45 L 213 45 L 213 19 L 217 18 L 217 14 L 204 14 Q 202 20 208 18 L 208 33 L 189 14 L 177 14 Z M 218 14 L 218 23 Q 226 26 223 18 L 230 18 L 230 41 L 226 41 L 227 45 L 246 45 L 245 41 L 241 41 L 241 18 L 249 18 L 249 23 Q 253 24 254 22 L 254 15 L 254 14 L 218 14 Z M 109 30 L 109 37 L 125 37 L 125 30 L 109 30 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 84.5 13 L 92 13.5 L 80.5 15 L 76 18.5 L 75 26.5 L 77.5 30 L 81 31.5 L 79.5 32 L 75 28.5 L 74 20.5 L 78.5 15 L 84.5 13 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 10 14 L 29.5 14 L 30 18 L 26 18.5 L 26 46.5 L 22.5 52 Q 17.8 55.3 8.5 54 L 5 53 L 5.5 51 Q 8.7 54.8 17.5 53 L 24 48.5 L 25 45.5 L 25 17 Q 30.2 18.7 29 15 L 11.5 15 L 11 17 L 16 17.5 L 16 47.5 L 13.5 51 Q 9.8 51.8 9 49.5 L 7.5 47 L 5.5 48 L 5 46 Q 8.8 45 10 47.5 Q 8.8 52.1 14 50 L 15 47.5 L 15 18 Q 7.9 20.4 10 14 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 32 14 L 50.5 14 L 51 18 L 47 18.5 L 47 36.5 L 50.5 41 Q 60 43 62 37.5 L 62 18.5 L 58 18 L 58 14 L 70.5 14 L 72 15.5 L 72 18 L 67 18.5 L 67 38.5 L 62.5 44 L 58.5 45 L 58.5 44 L 64 41.5 L 66 37.5 L 66 17 L 71 17 L 71 15 L 59.5 15 L 59 17 L 63 17.5 L 63 37.5 L 60.5 41 Q 57.3 43.3 50.5 42 L 47 39.5 L 46 37.5 L 46 17 Q 51.2 18.7 50 15 L 33.5 15 L 33 17 L 37 17.5 L 37 36.5 L 42.5 44 L 45 44.5 L 42.5 45 L 38 41.5 L 36 36.5 L 36 18 Q 30 20 32 14 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 93.5 14 Q 98.9 13.4 101 15 L 101 23 L 97.5 23 L 93.5 18 L 91 17.5 L 93.5 17 L 96 18 L 97 22 Q 102.3 23.8 100 16.5 Q 98.8 13.8 93.5 15 L 93.5 14 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 119.5 14 L 153.5 14 L 154 14.5 L 154 23 Q 146.5 25.5 149 18 L 142 18 L 142 40.5 L 146 41 L 146 44.5 L 145 44.5 L 145 42 L 141 41.5 L 141 17 L 150 17 L 150 22 L 153 22 L 153 15.5 L 152.5 15 L 119.5 15 L 119.5 14 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 155 14 L 173.5 14 L 174 18 L 170 18.5 L 170 41 Q 175.6 38.8 174 44.5 L 173 44.5 L 173 42 L 169 41.5 L 169 17 Q 174.2 18.7 173 15 L 156.5 15 L 156 17 L 160 17.5 L 160 42 Q 154.8 39.9 156 44.5 L 155 44.5 L 155 41 L 159 40.5 L 159 18 Q 153 20 155 14 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 177 14 L 188.5 14 L 207.5 33 L 208 18 L 204 18 L 204 16.5 L 205.5 17 L 209 17.5 L 209 35 L 202 29.5 L 188.5 15 L 178.5 15 L 178 17 L 182 17.5 L 182 42 Q 176.8 39.9 178 44.5 L 177 44.5 L 178.5 41 L 181 40.5 L 181 18 Q 175 20 177 14 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 204.5 14 L 216.5 14 L 217 18 L 213 18.5 L 213 44.5 L 211.5 45 L 212 43.5 L 212 17.5 L 216 17 L 216 15 L 204.5 15 L 204.5 14 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 218 14 L 253.5 14 L 254 14.5 L 254 21.5 Q 253.3 23.8 249.5 23 L 249.5 22 L 252 22 L 252 15 L 219.5 15 L 219 15.5 L 219 22 L 222 22 L 222 17 L 231 17 L 231 41.5 L 227 42 L 227 44.5 L 226 44.5 L 226 41 L 230 40.5 L 230 18 L 223 18 Q 225.5 25.5 218 23 L 218 14 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 87.5 16 L 90 16.5 L 87.5 17 L 87.5 16 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 83.5 17 L 86 17.5 L 83 19.5 L 84 23.5 L 82 22.5 L 81 20.5 L 83.5 17 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 123.5 17 L 132 17 L 132 42 Q 128.2 41 127 43.5 L 127.5 45 Q 124.9 45.8 126 42.5 Q 127.2 40 131 41 L 131 18.5 L 130.5 18 L 124 18 Q 125 21.8 122.5 23 L 119 22.5 L 122 22 Q 121 18.2 123.5 17 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 240 17 L 249 17.5 L 241 18 L 241 40.5 L 244.5 41 L 246 45 Q 242.7 46.1 244 42 L 240 41.5 L 240 17 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 85.5 24 L 96 26.5 L 93.5 27 L 85.5 25 L 85.5 24 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 185 24 L 192 29.5 L 206 44.5 L 186 26.5 L 186 40.5 L 190 41 L 190 44.5 L 189 44.5 L 189 42 L 185 41.5 L 185 24 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 99.5 28 L 102 29 Q 104.6 31.3 104 37.5 L 99.5 44 L 95.5 45 L 95.5 44 L 101 42 L 103 35.5 L 99.5 28 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 109 30 L 125 30 L 125 37 L 109 37 L 109 30 Z M 110 31 L 110 36 L 124 36 L 124 31 L 110 31 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 82.5 32 L 90 33.5 L 85.5 34 L 82.5 33 L 82.5 32 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 91.5 34 L 95 36.5 L 92.5 35 L 91.5 34 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 75 35 Q 78.8 34 80 36.5 Q 79.5 40.5 82.5 41 L 84 41.5 L 82.5 42 L 77.5 36 L 75 37.5 Q 73.8 43.6 76.5 43 L 81 44.5 L 77.5 45 L 74 42.5 Q 73.4 37.1 75 35 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 94.5 39 L 95 40.5 L 92.5 42 L 94.5 39 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 85.5 42 L 91 42.5 L 85.5 43 L 85.5 42 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 47.5 45 L 56 45.5 L 47.5 46 L 47.5 45 Z "
            />
            <path
              fill="rgb(128,128,128)"
              stroke="rgb(128,128,128)"
              stroke-width="1"
              opacity="0.20784313725490197"
              d="M 84.5 45 L 93 45.5 L 84.5 46 L 84.5 45 Z "
            />
            <path
              fill="rgb(127,127,127)"
              stroke="rgb(127,127,127)"
              stroke-width="1"
              opacity="0.9529411764705882"
              d="M 84.5 14 L 92.5 14 Q 93.8 16.3 98.5 15 L 100 16.5 Q 102.3 23.8 97 22 L 96 18 L 87.5 16 L 82 18.5 L 81 20.5 L 83.5 24 L 96.5 27 L 102 31.5 L 103 36.5 L 101 42 Q 95.3 46.9 81.5 45 L 75 43 Q 73.5 37.6 76.5 36 L 79 37.5 L 82.5 42 L 90.5 43 L 95 40.5 Q 96 35 92.5 34 L 81.5 32 L 76 28.5 L 75 20.5 L 78.5 16 L 84.5 14 Z "
            />
            <path
              fill="rgb(127,127,127)"
              stroke="rgb(127,127,127)"
              stroke-width="1"
              opacity="0.9529411764705882"
              d="M 11 15 L 28.5 15 L 29 17 L 25 17.5 L 25 45.5 L 20.5 52 L 8.5 53 L 5 50.5 L 6.5 47 L 9 48.5 L 10.5 51 Q 14.3 51.8 15 49.5 L 16 47.5 L 16 17 L 11 17 L 11 15 Z "
            />
            <path
              fill="rgb(127,127,127)"
              stroke="rgb(127,127,127)"
              stroke-width="1"
              opacity="0.9529411764705882"
              d="M 33 15 L 49.5 15 L 50 17 L 46 17.5 L 46 37.5 L 48.5 41 Q 51.7 43.3 58.5 42 L 62 39.5 L 63 37.5 L 63 17 Q 57.8 18.7 59 15 L 70.5 15 L 71 17 L 66 17.5 L 66 37.5 L 62.5 43 Q 56.7 46.7 45.5 45 L 38 39.5 L 37 36.5 L 37 17 Q 31.8 18.7 33 15 Z "
            />
            <path
              fill="rgb(127,127,127)"
              stroke="rgb(127,127,127)"
              stroke-width="1"
              opacity="0.9529411764705882"
              d="M 119 15 L 152.5 15 L 153 15.5 L 153 22 L 150 22 L 150 17 L 141 17 L 141 41.5 L 145 42 L 145 45 L 128.5 45 L 127 43.5 Q 128.2 41 132 42 L 132 17 L 123.5 17 Q 121 18.2 122 22 L 119 22 L 119 15 Z "
            />
            <path
              fill="rgb(127,127,127)"
              stroke="rgb(127,127,127)"
              stroke-width="1"
              opacity="0.9529411764705882"
              d="M 156 15 L 172.5 15 L 173 17 L 169 17.5 L 169 42 Q 174.6 40.1 173 45 L 156.5 45 L 156 42 L 160 41.5 L 160 17 Q 154.8 18.7 156 15 Z "
            />
            <path
              fill="rgb(127,127,127)"
              stroke="rgb(127,127,127)"
              stroke-width="1"
              opacity="0.9529411764705882"
              d="M 178 15 L 188.5 15 L 202 28.5 L 207.5 35 L 209 34.5 L 209 17 Q 205.2 18 204 15.5 L 216 15 L 216 17 L 212 17.5 L 212 43.5 Q 211 46 206.5 45 L 186.5 24 L 185 24.5 L 185 42 Q 190.6 40.1 189 45 L 178.5 45 L 178 42 L 182 41.5 L 182 17 Q 176.8 18.7 178 15 Z "
            />
            <path
              fill="rgb(127,127,127)"
              stroke="rgb(127,127,127)"
              stroke-width="1"
              opacity="0.9529411764705882"
              d="M 219 15 L 251.5 15 L 252 15.5 L 252 22 L 249 22 L 249 17 L 240 17 L 240 41.5 L 244 42 L 244 45 L 227.5 45 L 227 42 L 231 41.5 L 231 17 L 222 17 L 222 22 L 219 22 L 219 15 Z "
            />
            <path
              fill="rgb(127,127,127)"
              stroke="rgb(127,127,127)"
              stroke-width="1"
              opacity="0.9529411764705882"
              d="M 110 31 L 124 31 L 124 36 L 110 36 L 110 31 Z "
            />
          </svg>
        </section>
      </article>
    );
  }
}
