import { useEffect } from "react";
import HeaderImage from "../../components/common/header.image";
import ImageWithText from "../../components/common/image.with.text";
import scrollToTop from "../../helpers/scroll.to.top";
import AboutCTA from "./components/about.cta";
import AboutJustint from "./components/about.justint";
import Numbers from "./components/numbers";
import OurLocation from "./components/our.location";
import VisionMission from "./components/vision.mission";

export default function AboutUs() {
  
  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <section className="about-us-page w-100" style={{ gap: '50px' }}>
      <HeaderImage locateAt="about-us" />
      <AboutJustint />
      <Numbers />
      <VisionMission />
      <ImageWithText locateAt="about-us" />
      <OurLocation />
      <AboutCTA />
    </section>
  );
  }
  