import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  CarCoating,
  CarPPF,
  CarTinted,
  DecadesExpertise,
  ExpertVehicle,
  JustintTeam,
  Sample,
  SampleGrey,
} from "../../assets/images";
import CommonText from "./common.text";

export default function ImageWithText({ locateAt }) {
  const imageWithText = {
    home: {
      image: ExpertVehicle,
    },
    "car-tinted": {
      image_text_h5: "Do you know tint can give you a healthy life",
      image_text_p:
        "Enhance Style and Appearance. Automotive window tinting provides privacy and keeps your valuables safe f rom prying eyes. Helps keep your interior and loved ones safe f rom harmful UV rays. Reduce heat and distracting glare. Our automotive ﬁlms include various grade of solar control window ﬁlm, safety and security ﬁlm.",
      image: CarTinted,
    },
    "car-ppf": {
      image_text_h5: "Technologically Advance Paint Protection",
      image_text_p: `Jus-Tint Paint protection film, also called as “PPF”, is an invisible protection film developed to protect your car painted paint finish on a vehicle's impact zones, such as the leading edge of the hood and the bumper. Jus-Tint paint protection film has an external solvent resistance topcoat combining superior elastic feature and exceptional clarity with a high stretch ability and tack adhesive. Jus-Tint PPF providing protection from nicks, stone chips, abrasions and bumper scuffs. Paint Protection Film can also be used to protect headlights from cracks, scratches, chips, yellowing and hazy buildup. Essentially invisible when installed to car body, it makes excellent paint colour shine through without changing the outlook of your car.`,
      image: CarPPF,
    },
    "car-coating-top": {
      image_text_h5: "9H NANO CERAMIC COATING",
      image_text_p: `Jus-Tint Paint protection film, also called as “PPF”, is an invisible protection film developed to protect your car painted paint finish on a vehicle’s impact zones, such as the leading edge of the hood and the bumper. Jus-Tint  paint protection film has an external solvent resistance topcoat combining superior elastic feature and exceptional clarity with a high stretch ability and tack adhesive. Jus-Tint PPF providing protection from nicks, stone chips, abrasions and bumper scuffs. Paint Protection Film can also be used to protect headlights from cracks, scratches, chips, yellowing and hazy buildup. Essentially invisible when installed to car body, it makes excellent paint colour shine through without changing the outlook of your car.`,
      image: CarCoating,
    },
    "car-coating-bottom": {
      image_text_h5: "10H NANO CERAMIC COATING",
      image_text_p: `Jus-Tint Paint protection film, also called as “PPF”, is an invisible protection film developed to protect your car painted paint finish on a vehicle’s impact zones, such as the leading edge of the hood and the bumper. Jus-Tint  paint protection film has an external solvent resistance topcoat combining superior elastic feature and exceptional clarity with a high stretch ability and tack adhesive. Jus-Tint PPF providing protection from nicks, stone chips, abrasions and bumper scuffs. Paint Protection Film can also be used to protect headlights from cracks, scratches, chips, yellowing and hazy buildup. Essentially invisible when installed to car body, it makes excellent paint colour shine through without changing the outlook of your car.`,
      image: CarCoating,
    },
    "about-us": {
      image_text_h5: "Meet Our Founder",
      image_text_p: (
        <p>
          Vincent Tan have over 20 years experience in this ﬁeld. <br />
          <br />
          He lead the company to be a very successful team based on his
          knowledge and experience. <br />
          <br />
          He strive to help each customer get quality service and professional
          comments and suggestions.
        </p>
      ),
      image: JustintTeam,
      founder_owner: "Justint Team",
    },
  };

  return (
    <article className={`image-with-text --${locateAt}`}>
      {imageWithText[locateAt] && (
        <section className="image-with-text-section">
          <CommonText locateAt={locateAt} />
          <article className="image-frame">
            <LazyLoadImage
              src={imageWithText[locateAt].image}
              alt={imageWithText[locateAt].image_text_h5}
            />
            {imageWithText[locateAt].founder_owner && (
              <article className="founder-text">
                <h6>{imageWithText[locateAt].founder_owner}</h6>
                <p>The Experts Behind Our Success</p>
              </article>
            )}
          </article>
        </section>
      )}
    </article>
  );
}
