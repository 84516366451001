import { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedBodyType } from "../../redux/reducer/commonReducer";
import CommonStep from "./common.step";

export default function ChooseBodyType({ bodyTypeList, className }) {
    const dispatch = useDispatch()
    const { selectedBodyType } = useSelector((state) => state.common)

    const handleSelectBodyType = (bodyType) => {
        dispatch(setSelectedBodyType(bodyType))
    }

    useEffect(() => {
        dispatch(setSelectedBodyType(bodyTypeList[0].value))
    }, [])

    return (
        <section className="choose-body-type">
            <CommonStep no={bodyTypeList.length > 3 ? 3 : 1}  title="Select Car Body Type" className="mb-4" style={{ marginTop: '-2em' }} />
            <article className={`body-type-list ${className}`}>
                {bodyTypeList?.map((bodyType, bodyTypeIndex) => (
                    <article 
                        onClick={() => handleSelectBodyType(bodyType.value)}
                        className={`body-type-item ${selectedBodyType === bodyType.value ? '--active' : ''} pointer`} 
                        key={bodyTypeIndex}
                    >
                        <h4>{bodyType.label}</h4>
                        <p>{bodyType.description}</p>
                        <article className="body-type-frame">
                            <LazyLoadImage 
                                src={bodyType.image}
                                alt={bodyType.label}
                            />
                        </article>
                    </article>
                ))}
            </article>
        </section>
    );
}