import { LazyLoadImage } from "react-lazy-load-image-component";
import { CoatingAward, WhyJustintImg } from "../../../assets/images";
import { Lambo } from "../../../assets/videos";

export default function CoatingDescription() {
  return (
    <article className="coating-description">
      <section
        className="coating-frame w-100"
        dangerouslySetInnerHTML={{
          __html: `
                <video
                    playsinline
                    autoPlay 
                    muted
                    loop
                    src=${Lambo}
                />
            `,
        }}
      ></section>
      <article className="description-section">
        <section className="description-frame">
          <LazyLoadImage src={CoatingAward} alt="" loading="eager" />
        </section>
        <section className="description-text">
          <h2>Award-Winning Ceramic Coating Excellence</h2>
          <p>
            Our ceramic coating services have been recognized for exceptional
            quality and performance. With industry-leading products and
            award-winning techniques, we ensure your car receives top-tier
            protection and a long-lasting shine that stands out.
          </p>
        </section>
      </article>
    </article>
  );
}
