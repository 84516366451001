import { createSlice } from "@reduxjs/toolkit";

export const initialData = {
  isOpenLoginModal: false,
  isOpenMenuTab: false,
  isOpenAccessoriesModal: false,
  isOpenEditPriceModal: false,
  isOpenFilterModal : false
};

const dataSlice = createSlice({
  name: "modal",
  initialState: initialData,
  reducers: {
    openLoginModal: (state, action) => {
      state.isOpenLoginModal = true;
    },
    openAccessoriesModal: (state, action) => {
      state.isOpenAccessoriesModal = true;
    },
    openEditPriceModal: (state, action) => {
      state.isOpenEditPriceModal = true;
    },
    openFilterModal: (state, action) => {
      state.isOpenFilterModal = true;
    },
    openMenuTab: (state, action) => {
      state.isOpenMenuTab = true;
    },
    closeLoginModal: (state, action) => {
      state.isOpenLoginModal = false;
    },
    closeAccessoriesModal: (state, action) => {
      state.isOpenAccessoriesModal = false;
    },
    closeEditPriceModal: (state, action) => {
      state.isOpenEditPriceModal = false;
    },
    closeFilterModal: (state, action) => {
      state.isOpenFilterModal = false;
    },
    closeMenuTab: (state, action) => {
      state.isOpenMenuTab = false;
    },
    closeAllModal: (state, action) => {
      state.isOpenLoginModal = false;
      state.isOpenMenuTab = false;
      state.isOpenAccessoriesModal = false;
      state.isOpenEditPriceModal = false;
      state.isOpenFilterModal = false;
    },
    // used for modalbox animation
    setAnimationModal: (state, action) => {
      state.isAnimationModal = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
  },
  extraReducers: {
  },
});

export const { 
    openLoginModal,
    openAccessoriesModal,
    openEditPriceModal,
    openFilterModal,
    openMenuTab, 

    closeLoginModal, 
    closeMenuTab,
    closeAccessoriesModal,
    closeEditPriceModal,
    closeFilterModal,

    closeAllModal,
    setAnimationModal,
    setShowModal
} = dataSlice.actions;

export default dataSlice.reducer;
