import { LazyLoadImage } from "react-lazy-load-image-component";
import { AIA, AIG, Allianz, AXA, Berjaya, Chubb, Etiqa, Generali, GreatEastern, Lonpac, MSIG, Pacific, PO, Progressive, RHB, TakafulIkhlas, TakafulMalaysia, TokyoMarine, Tune, Zurich } from "../../../assets/images";

export default function InsurancePanel() {
    const insuranceList = [
        Etiqa, 
        Chubb, 
        PO, 
        TakafulIkhlas, 
        TakafulMalaysia, 
        AIG, 
        TokyoMarine, 
        Pacific, 
        AXA, 
        MSIG, 
        RHB, 
        GreatEastern, 
        Allianz, 
        Zurich, 
        Lonpac, 
        Tune, 
        Berjaya,
        Generali, 
        AIA, 
        Progressive
    ]

    return (
      <article className="insurance-panel">
        <h3>All Insurance Panel</h3>
        <section className="insurance-list">
            {insuranceList.map((insurance, insuranceIndex) => (
                <article className="insurance-frame" key={insuranceIndex}>
                    <LazyLoadImage src={insurance} />    
                </article>
            ))}
        </section>
        {/* <section className="coverage-bg" /> */}
      </article>
    );
}
  