import { useDispatch, useSelector } from "react-redux";
import { setSelectedTintedSeries, setSelectedTintedType } from "../../../redux/reducer/serviceReducer";
import CommonStep from "../../../components/common/common.step";
import { ActiveJustint, ActiveSolar, InactiveJustint, InactiveSolar } from "../../../assets/images";

export default function ChooseSeries() {
    const dispatch = useDispatch()
    const { tintedSeriesList, selectedTintedSeries, selectedTintedType } = useSelector((state) => state.service);

    const handleSelectSeries = (series) => {
        dispatch(setSelectedTintedSeries(series))
        
        if(series === 'jt_series') {
            dispatch(setSelectedTintedType('solar'))
        } else {
            dispatch(setSelectedTintedType('lx_series'))
        }
    }

    const handleSelectType = (type) => {
        dispatch(setSelectedTintedType(type))
    }

    return (
        <section className="choose-series">
            <CommonStep no={1} title="Select Tinted Series" className="mb-4"  />
            <article className="series-list --brand">
                {Object.entries(tintedSeriesList).map(([series], seriesIndex) => (
                    <article 
                        onClick={() => handleSelectSeries(series.toLowerCase().replace(/\s+/g, '_'))}
                        className={`series-item ${selectedTintedSeries === series.toLowerCase().replace(/\s+/g, '_') ? '--active' : ''} pointer`} 
                        key={seriesIndex}
                        style={{ 
                            backgroundImage: `url(${series === 'JT Series' ? selectedTintedSeries === 'jt_series' ? ActiveJustint : InactiveJustint : selectedTintedSeries === 'solar_gard' ? ActiveSolar : InactiveSolar})`,
                        }}
                    >
                        {/* <h4>{series}</h4>
                        <p>series</p> */}
                    </article>
                ))}
            </article>
            <CommonStep no={2} title="Select Tinted Type" className="mt-5 mb-4" />
            <article className="series-list">
                {Object.values(tintedSeriesList)
                    .find(seriesArray => seriesArray.some(series => series.series_value === selectedTintedSeries))
                    .map((type, typeIndex) => (
                        <article 
                            onClick={() => handleSelectType(type.type_value)}
                            className={`series-item ${type.type_name === 'LX Series' ? '--lx-series' : '--series'} ${selectedTintedType === type.type_value ? '--active' : ''} pointer`} 
                            key={typeIndex}
                        >
                            <h4>{type.type_name}</h4>
                            <p>{type.series_name}</p>
                        </article>
                    ))
                }
            </article>
        </section>
    );
}