import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import useWindowDimensions from "../hook/use.window.dimensions";
import { useSelector } from "react-redux";

export default function InputCount({
  className,
  handleUpdateCart,
  current_id,
  current_quantity,
  current_price,
  current_note,
}) {
  const { width } = useWindowDimensions();
  const [quantity, setQuantity] = useState(current_quantity);
  const { cartItemLoading } = useSelector((state) => state.cart_order);

  const handleAddQuantity = () => {
    handleUpdateCart(current_id, quantity + 1, current_price, current_note);
  };

  const handleMinusQuantity = () => {
    if (
      quantity !== 1 &&
      cartItemLoading?.id !== current_id &&
      !cartItemLoading?.type
    ) {
      handleUpdateCart(current_id, quantity - 1, current_price, current_note);
    }
  };

  const handleChangeQuantity = (e) => {
    if (cartItemLoading?.id !== current_id && !cartItemLoading?.type) {
      handleUpdateCart(current_id, e.target.value, current_price, current_note);
    }
  };

  useEffect(() => {
    const inputs = document.querySelectorAll(".input-quantity");

    if (inputs?.length > 0) {
      inputs.forEach((input) => {
        input.style.width =
          (input.value?.length ?? 2) + (width > 600 ? 3 : 1.5) + "ch";
      });
    }
  }, [quantity, cartItemLoading]);

  useEffect(() => {
    setQuantity(current_quantity);
  }, [cartItemLoading]);

  return (
    <article
      className={`element _input-count ${width < 800 ? "w-100 " : ""} ${
        className ? className : ""
      }`}
    >
      <section>
        <article
          className={`quantity-button ${
            quantity === 1 ||
            (cartItemLoading?.id === current_id && !cartItemLoading?.type)
              ? "--disabled"
              : ""
          } pointer`}
          onClick={() => handleMinusQuantity()}
        >
          <Icon icon="mingcute:minus-circle-fill" />
        </article>
      </section>
      <section
        className={width < 800 ? "w-100 text-center flex justify-center" : ""}
      >
        {cartItemLoading?.id === current_id && !cartItemLoading?.type ? (
          <article className="input-quantity flex justify-center">
            <Icon icon="eos-icons:three-dots-loading" className="--loading" />
          </article>
        ) : (
          <input
            readOnly
            value={quantity}
            className="input-quantity pointer"
            onChange={(e) => handleChangeQuantity(e)}
          />
        )}
      </section>
      <section>
        <article
          className={`quantity-button ${
            cartItemLoading?.id === current_id && !cartItemLoading?.type
              ? "--disabled"
              : ""
          } pointer`}
          onClick={() => handleAddQuantity()}
        >
          <Icon icon="mingcute:add-circle-fill" />
        </article>
      </section>
    </article>
  );
}
