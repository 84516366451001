import { Form, Formik } from "formik";
import Input from "../../../components/element/input";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useEffect, useState } from "react";
import { getProfile, updateProfile } from "../../../redux/reducer/profileCommissionReducer";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { DatePicker } from 'antd'
import * as Yup from 'yup'
import Button from "../../../components/element/button";
import moment from "moment";
import { DefaultJustint, SampleGrey } from "../../../assets/images";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getOrderList } from "../../../redux/reducer/cartOrderReducer";
import Lottie from "lottie-react";
import { Empty, Loading } from "../../../assets/lotties";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

const { RangePicker } = DatePicker;

export default function OrderList() {
    const dispatch = useDispatch()
    const { width } = useWindowDimensions()
    const { orderList, listLoading } = useSelector((state) => state.cart_order);
    const { token, username } = useSelector((state) => state.common);

    const [ searchDate, setSearchDate ] = useState([]);
    const [ searchValue, setSearchValue ] = useState('');

    const orderParts = [
        {
            value: [
                {
                    label: 'pending',
                    value: 'appointment_date',
                    icon: 'ci:calendar-check'
                },
                {
                    label: 'processing',
                    value: 'branch',
                    icon: 'tdesign:map-checked'
                },
                {
                    label: 'completed',
                    value: 'estimate_delivery_time',
                    icon: 'mdi:wrench-check-outline'
                },
            ],
            type: 'step'
        },
        {
            label: 'Product Orders',
            value: 'order_items',
            type: 'array'
        },
        {
            label: 'Customer Details',
            value: [
                {
                    label: 'Name',
                    value: 'user_name'
                },
                {
                    label: 'Contact No.',
                    value: 'contact'
                },
                {
                    label: 'Car Chassis No.',
                    value: 'car_chassis_number'
                },
                {
                    label: 'Car Plate No.',
                    value: 'car_no_plate'
                },
            ],
            type: 'array'
        },
        {
            label: 'Total Item',
            value: 'number_of_items'
        },
        {
            className: '--highlight',
            type: 'price',
            label: 'Order Total',
            value: 'total_price'
        }
    ]

    const handleDateChange = (date, dateString) => {
        if (date?.length > 0) {
            if(date[0].isValid() && date[1].isValid()) {
                setSearchDate([dateString[0], dateString[1]])
            }
        } else {
            setSearchDate([])
        }
    }

    const handleSearchOrder = () => {
        if(token && username) {
            dispatch(getOrderList({
                start_date: searchDate.length > 0 ? searchDate[0] : '',
                end_date: searchDate.length > 0 ? searchDate[1] : '',
                search: searchValue
            }))
        }
    }

    useEffect(() => {
        if(token && username) {
            dispatch(getOrderList({}))
        }
    }, [token, username])

    return (
        <article className="w-100 flex flex-column items-center" style={{ gap: '50px' }}>
            <section className="order-card">
                <article className="flex flex-wrap justify-between items-center">
                    <h5>My Order</h5>
                    <section className={`relative ${width < 820 ? 'w-100 ps-4 pe-4' : ''}`}>
                        <article className="search-bar">
                            <RangePicker
                                value={searchDate?.length > 0 ? [dayjs(searchDate[0]), dayjs(searchDate[1])] : []}
                                onChange={(date, dateString) => {
                                    handleDateChange(date, dateString)
                                }}
                                popupClassName={width < 550 ? "dateRangePicker" : undefined}
                            />
                            <section className="search-divider" />
                            <section className="flex w-100">
                                <input value={searchValue} placeholder="Search something.." onChange={(e) => setSearchValue(e.target.value)} />
                                <Button onClick={() => handleSearchOrder()}>
                                    <Icon icon="majesticons:search-line" />
                                    <p>Search</p>
                                </Button>
                            </section>
                        </article>
                    </section>
                </article>
                <hr />
                {!listLoading ?
                    <article className="order-list">
                        {orderList?.length > 0 ? 
                            orderList.map((order, orderIndex) => (  
                                <article className="order-item" key={orderIndex}>
                                    <>
                                        <section className="flex flex-wrap items-center justify-between gap-3">
                                            <article className="flex items-center gap-3">
                                                <h6 className="--order-id">Order #{order.invoice_number}</h6>
                                                <article className={`order-status --${order.status === 'appointment set' ? 'appointment' : order.status}`}>
                                                    {/* <p>Appointment Set {order.status !== 'appointment set' ? `- ${order.appointment_date}` : ''}</p> */}
                                                    <p>{order.status}</p>
                                                </article>
                                            </article>
                                            <article className="flex items-center gap-3">
                                                <Icon icon="mdi:date-range" className="--calendar" />
                                                <h6>{order.created_at}</h6>
                                            </article>
                                        </section>
                                        <section className="info-list">
                                            {orderParts.map((part, partIndex) => (
                                                <article className={part.type !== 'array' && part.type !== 'step' ? "flex items-center" : ""} key={partIndex}>
                                                    <h6 className={`${part.type === 'array' ? "--order" : ""} ${part.className ? part.className :  ''}`}>
                                                        {part.label} {part.type !== 'array' && part.type !== 'step' ? ':' : ''}&nbsp;&nbsp;
                                                    </h6>
                                                    {part.type === 'array' ?
                                                        part.label === 'Product Orders' ?
                                                            order[part.value]?.length > 0 && order[part.value].map((item, itemIndex) => {
                                                                const isVideoFile = item.media?.length > 0 ? [
                                                                    "mp4",
                                                                    "MP4",
                                                                    "mov",
                                                                    "MOV",
                                                                    "webm",
                                                                    "WEBM",
                                                                    'mkv',
                                                                    'MKV'
                                                                ].includes(item.media[0].url?.split(".").pop()) : false;

                                                                return (
                                                                    <section className="order-grid" key={itemIndex}>
                                                                        <article className="order-frame">
                                                                            {isVideoFile ?
                                                                                <video 
                                                                                src={item.media?.length > 0
                                                                                    ? item.media[0].url
                                                                                    : DefaultJustint
                                                                                } 
                                                                                // controls 
                                                                                />
                                                                            : 
                                                                                <LazyLoadImage 
                                                                                src={item.media?.length > 0 
                                                                                    ? item.media[0].url 
                                                                                    : DefaultJustint} 
                                                                                />
                                                                            }
                                                                        </article>
                                                                        <article>
                                                                            <h6 className="two-line">{item.product_name}</h6>
                                                                            <span>{item.product_sku_code}</span>
                                                                        </article>
                                                                        <article className="text-center">
                                                                            <h6>{item.quantity}</h6>
                                                                            <span>Qty</span>
                                                                        </article>
                                                                        <article className="text-center">
                                                                            <h6>{item.subtotal}</h6>
                                                                            <span>Price</span>
                                                                        </article>
                                                                    </section>
                                                                )
                                                            })
                                                        :
                                                            <section className="customer-grid">
                                                                {part.value.map((customer, customerIndex) => (
                                                                    <article className="flex justify-between items-baseline w-100" key={customerIndex}>
                                                                        <h6>{customer.label}</h6>
                                                                        <span>{order[customer.value] ?? '-'}</span>
                                                                    </article>
                                                                ))}
                                                            </section>
                                                    : part.type === 'step' ?
                                                        <section className={
                                                            `order-step --${order.status === 'pending' || order.status === 'appointment_set' ? 'pending'
                                                              : order.status === 'processing' || order.status === 'completed' ? 'processing'
                                                              : order.status === 'paid' ? 'completed'
                                                              : 'pending'
                                                            }`} 
                                                        >
                                                            {part.value.map((step, stepIndex) => (
                                                                <article className="step-layout relative">
                                                                    <section 
                                                                        className={`step ${order[step.value] ? 'items-start' : 'items-center'}`}
                                                                        key={stepIndex}
                                                                    >
                                                                        <article className="step-circle">
                                                                            <Icon icon={step.icon} className={stepIndex + 1 === part.value.length ? '--wrench' : '' } />
                                                                        </article>
                                                                        <article className={`flex flex-column ${width > 600 ? 'items-center gap-2' : 'items-start gap-1'} justify-center`}>
                                                                            <h6>{step.label}</h6>
                                                                            <article className="flex items-center gap-1">
                                                                                {order[step.value] ? <span>{order[step.value]}</span> : ''}
                                                                                {/* <Icon icon="ci:info" /> */}
                                                                            </article>
                                                                        </article>
                                                                    </section>
                                                                    {(stepIndex + 1 !== part.value.length) && 
                                                                        <hr className={
                                                                            `step-line --${((step.label === 'pending' && (order.status === 'processing' || order.status === 'completed' || order.status === 'paid'))
                                                                             || (step.label === 'processing' && order.status === 'paid')) ? 'active' : ''
                                                                        }`} />
                                                                    }
                                                                </article>
                                                            ))}
                                                        </section>          
                                                    :
                                                        <p className={part.className ?? ''}>
                                                            {part.className ? 'RM' : ''}&nbsp;
                                                            {order[part.value]}
                                                        </p>
                                                    }
                                                </article>
                                            ))}
                                        </section>
                                        {(orderIndex + 1) !== orderList.length ?
                                            <hr className="--grey mb-5" /> : <article className="mb-1"></article>
                                        }
                                    </>
                                </article>
                            ))
                        :
                            <article className="w-100 minh-480 flex flex-column items-center">
                                <article className="common-lottie --empty">
                                    <Lottie loop={2} animationData={Empty} />
                                </article>
                                <h6 className="--loading text-center">No data found</h6>
                            </article>
                        }
                    </article>
                : 
                    <article className="w-100 minh-480 flex flex-column items-center">
                        <article className="common-lottie">
                            <Lottie animationData={Loading} />
                        </article>
                        <h6 className="--loading text-center">Loading ..</h6>
                    </article>
                }
            </section>
        </article>
    );
  }
  