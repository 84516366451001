import { useDispatch, useSelector } from "react-redux";
import { setSelectedPackageOrAlacarte } from "../../redux/reducer/commonReducer";
import { setSelectedTintedPackage } from "../../redux/reducer/serviceReducer";
import { useFormikContext } from "formik";

export default function ChoosePackageOrAlacarte({ locateAt, resetForm, setFieldValue }) {
    const dispatch = useDispatch()
    const { selectedPackageOrAlacarte } = useSelector((state) => state.common);

    const handleSelectAlacarte = () => {
        if(locateAt === 'tinted') {
            resetForm()
            setFieldValue('is_package', false)
            dispatch(setSelectedTintedPackage({}))
        }
        dispatch(setSelectedPackageOrAlacarte('alacarte'))
    }

    const handleSelectPackage = () => {
        dispatch(setSelectedPackageOrAlacarte('package'))
    }

    return (
        <section className="common-select">
            <article className="package-or-alacarte">
                <article 
                    onClick={() => handleSelectAlacarte()}
                    className={`select-item ${selectedPackageOrAlacarte === "alacarte" ? '--active' : ''} pointer`} 
                >
                    <h4>Alacarte</h4>
                    <p>Pick Your Own</p>
                </article>
                <article 
                    onClick={() => handleSelectPackage()}
                    className={`select-item ${selectedPackageOrAlacarte === "package" ? '--active' : ''} pointer`} 
                >
                    <h4>Package</h4>
                    <p>All In One</p>
                </article>
            </article>
        </section>
    );
}