import { useState } from "react";

import { Field, useFormikContext } from "formik";
import { MDBCol, MDBInput, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
// import { DatePicker } from "antd";
// import dayjs from 'dayjs';

export default function Input(props) {
  const {
    label,
    type,
    name,
    disabled,
    placeholder,
    verification,
    labelClassName,
    className,
    errorClassName,
    handleVerification,
    as,
    autoFocus,
    inputMode,
    maxLength,
    onChange,
    isRequired,
    inputClassName,
    onClick,
    readOnly,
    onKeyDown,
    showError = true,
    ref,
    handleSendOtp,
    firstTimeRequest,
    isRequesting,
    otpCountDown,
    errorStatus,
    formatTime,
    prefix,
  } = props;

  const { values, errors, setFieldValue, setFieldError } = useFormikContext();

  const [inputFocus, setInputFocus] = useState(false);

  const disabledDate = (current) => {
    const today = new Date();
    return current && current.valueOf() > today.setHours(23, 59, 59, 999);
  };

  const handleFocus = (e) => {
    setInputFocus(true);

    if (type === "number" && e) {
      e.target.addEventListener(
        "wheel",
        function (e) {
          e.preventDefault();
        },
        { passive: false }
      );
    }
  };

  const handleBlur = () => {
    setInputFocus(false);
  };

  const formatDate = (value) => {
    const formattedDate = value.replace(/-/g, "/");

    return formattedDate;
  };

  const handleDateChange = (date, dateString) => {
    if (date && date.isValid()) {
      setFieldValue(name, formatDate(dateString));
    }
  };

  return (
    <>
      <article
        className={`
          element _text 
          ${
            (errors && errors[name]) || name === "code"
              ? "--error mb-0"
              : !as
              ? "mb-4"
              : "mb-4"
          } 
          ${className ? className : ""} 
        `}
      >
        {label && (
          <section>
            <label className={labelClassName}>
              {isRequired && <span>* </span>}
              {label}
            </label>
          </section>
        )}
        <section
          className={`element _text ${
            as
              ? `${as} ${inputClassName ? inputClassName : ""} ${
                  disabled ? "--disabled" : ""
                }`
              : `mdb-field ${inputClassName ? inputClassName : ""} ${
                  disabled ? "--disabled" : ""
                }`
          }`}
          style={{
            border:
              !values[name] &&
              inputClassName !== "phone_no" &&
              inputClassName !== "phone_no_otp"
                ? errors && errors[name]
                  ? "0.2rem solid #ff3131cc"
                  : !as
                  ? `0.2rem solid #D8D7E5`
                  : ""
                : !as
                ? ""
                : "",
            background:
              values[name] && as !== "textarea" ? "transparent" : "transparent",
          }}
        >
          {as === "textarea" && inputClassName === "special" ? (
            <Field
              onFocus={() => handleFocus()}
              onBlur={handleBlur}
              type={type ? type : "text"}
              name={name}
              values={values[name]}
              placeholder={placeholder}
              disabled={disabled}
              as={"textarea"}
              className="focus"
              innerref={autoFocus ? autoFocus : undefined}
              inputMode={inputMode ? inputMode : ""}
              maxLength={maxLength ? maxLength : ""}
              onChange={onChange ? onChange : undefined}
            />
          ) : as === "withdraw-field" ? (
            <Field
              onFocus={() => handleFocus()}
              onBlur={handleBlur}
              type={type ? type : "text"}
              name={name}
              values={values[name]}
              placeholder={placeholder}
              disabled={disabled}
              className="focus"
              // innerref={autoFocus}
              inputMode={inputMode ? inputMode : ""}
              maxLength={maxLength ? maxLength : ""}
              onChange={onChange ? onChange : undefined}
              onKeyPress={onKeyDown ? onKeyDown : undefined}
            />
          ) : as ? (
            inputClassName === "phone_no" ||
            inputClassName === "phone_no_otp" ? (
              <article className="prefix">
                {(inputClassName === "phone_no" ||
                  inputClassName === "phone_no_otp") && (
                  <article>
                    <label>+60</label>
                  </article>
                )}
                <Field
                  onFocus={() => handleFocus()}
                  onBlur={handleBlur}
                  name={name}
                  disabled={disabled}
                  values={values[name]}
                  placeholder={placeholder}
                  type={type ? type : "text"}
                  as={as === "textarea" ? as : ""}
                  className={inputClassName ? inputClassName : "focus"}
                  innerref={autoFocus ? autoFocus : undefined}
                  inputMode={inputMode ? inputMode : ""}
                  maxLength={maxLength ? maxLength : ""}
                />
              </article>
            ) : (
              <Field
                onFocus={(e) => handleFocus(e)}
                onBlur={handleBlur}
                name={name}
                disabled={disabled}
                values={values[name]}
                placeholder={placeholder}
                type={type ? type : "text"}
                as={as === "textarea" ? as : ""}
                className={inputClassName ? inputClassName : "focus"}
                innerref={autoFocus ? autoFocus : undefined}
                inputMode={inputMode ? inputMode : ""}
                maxLength={maxLength ? maxLength : ""}
                onKeyPress={(e) => {
                  if (type === "number") {
                    const allowedRegex = /^[a-zA-Z0-9]+$/;
                    if (!allowedRegex.test(e.key)) {
                      e.preventDefault();
                    }
                  }
                }}
              />
            )
          ) : (
            // : type === 'date' ?
            //   <article className="flex items-center relative">
            //     <Field
            //       onFocus={handleFocus}
            //       onBlur={handleBlur}
            //       label={
            //         <label
            //         //  style={{ color: (values[name] || inputFocus) && !inputClassName ? '#333333' : '#9EBCD8', fontFamily: 'poppins-medium' }}
            //         >
            //             {placeholder}
            //             {isRequired && <span style={{ color: '#EE5B5B' }}> *</span>}
            //         </label>
            //       }
            //       disabled={disabled}
            //       as={MDBInput}
            //       className="focus date-input"
            //       innerref={autoFocus ? autoFocus : undefined}
            //       inputMode={inputMode ? inputMode : ""}
            //       maxLength={10}
            //     />
            //     <DatePicker
            //       value={dateValue}
            //       onChange={handleDateChange}
            //       placeholder="DD-MM-YYYY"
            //       format='DD-MM-YYYY'
            //       disabledDate={disabledDate}
            //     />
            //   </article>
            <Field
              onFocus={() => handleFocus()}
              onBlur={handleBlur}
              type={type ? type : "text"}
              name={name}
              values={values[name]}
              label={
                <label
                //  style={{ color: (values[name] || inputFocus) && !inputClassName ? '#333333' : '#9EBCD8', fontFamily: 'poppins-medium' }}
                >
                  {placeholder}
                  {isRequired && <span style={{ color: "#EE5B5B" }}> *</span>}
                </label>
              }
              disabled={disabled}
              as={MDBInput}
              className="focus"
              innerref={autoFocus ? autoFocus : undefined}
              inputMode={inputMode ? inputMode : ""}
              maxLength={maxLength ? maxLength : ""}
              onClick={onClick ? onClick : undefined}
              readOnly={readOnly ? readOnly : ""}
            />
          )}
          {verification && (
            <div className="verification-button">
              <button type="button" onClick={() => handleVerification()}>
                SEND
              </button>
            </div>
          )}
        </section>
      </article>
      {showError &&
      errors &&
      errors[name] &&
      as !== "withdraw-field" &&
      type !== "code" ? (
        <div
          className={`element _errors text-right no-padding error-message ${
            errorClassName ?? ""
          } ${!as ? "ps-2" : as === "round-field" ? "ps-3" : ""}`}
        >
          {errors[name]}
        </div>
      ) : null}
    </>
  );
}
