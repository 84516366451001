import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  AboutJustintOne,
  AboutJustintTwo,
  SampleFile,
} from "../../../assets/images";

export default function AboutJustint() {
  return (
    <article className="w-100 flex flex-column items-center">
      <section className="about-justint">
        <article className="about-frame --left">
          <LazyLoadImage src={AboutJustintOne} alt="about-justint" />
        </article>
        <article className="about-card">
          <article className="about-frame --right">
            <LazyLoadImage src={AboutJustintTwo} alt="about-justint" />
          </article>
          <h4>About Jus-Tint</h4>
          <p>
            Justint was established in 2005 by founder Mr. Vincent Tan, marking
            our beginning in Bukit Tinggi, Klang. To meet the increasing demand
            for our services, we opened a second branch in Bandar Baru, Klang,
            in 2011, allowing us to extend our commitment to excellence.
            <br />
            <br />
            Our team is composed of qualified Service Advisors with strong
            technical backgrounds and communication skills. Trained to
            prioritize customer satisfaction, they represent Justint's values in
            every interaction, delivering professional, efficient service with a
            personal touch.
          </p>
          {/* <p>Founded in 2005</p> */}
        </article>
      </section>
    </article>
  );
}
