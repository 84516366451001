import './index.css';
import App from './App';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import store from "./redux/store"; 
import { Provider } from 'react-redux';

import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import { CommonToast } from './components/common/common.toast';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Suspense fallback={(<div></div>)}>
      <CommonToast>
        <App />
      </CommonToast>
    </Suspense>
  </Provider>
);

reportWebVitals();
