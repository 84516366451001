//lib
import axios from 'axios'
import apiService from './api.service';
import { openLoginModal } from '../redux/reducer/modalReducer';
import { getToken, getUsername, getUserRank, getUserRole } from '../redux/reducer/commonReducer';

function handleErrorResponse(error, toast, dispatch) {
  const authError = error.response && error.response.status === 401;
  const badReqError = error.response && error.response.status === 400;
  const versionUpdateError = error.response && error.response.status === 410;
  const manyAttemptsError = error.response && error.response.status === 429;
  const unexpectedError = error.response && error.response.status === 500;
  const maintainanceError = error.response && error.response.status === 503;
  const networkError = !error.response;

  const merchantName = localStorage.getItem('merchant_name');
  
  // handle no network
  // if (networkError) {
  //   toast.error('Server is busy right now. Please refresh and try again later.');
  // }

  //handle 401
  if (authError) {
    localStorage.clear()
    dispatch(getToken())
    dispatch(getUsername())
    dispatch(getUserRank())
    dispatch(getUserRole())

    toast.error('Your session has expired. Please log in again.');

    dispatch(openLoginModal())
  }

  // handle 429
  if(manyAttemptsError) {
    toast.error('Too many attempts, please try again later.');
  }

  // handle 500
  if (unexpectedError) {
    toast.error('Oops! Something went wrong unexpectedly. Please contact support for assistance.');
  }

  return Promise.reject(error);
}

function initializeInterceptors(toast, dispatch) {
  axios.interceptors.response.use(
    response => response,
    error => {
      // Sentry.captureException(error, {
      //   extra: {
      //     payload: {
      //       requestUrl: error?.config?.url,
      //       requestData: {
      //         method: error?.config?.method,
      //         headers: error?.config?.headers,
      //         data: error?.config?.data,
      //         params: error?.config?.params,
      //       },
      //       status: error?.response?.status,
      //       statusText: error?.response?.statusText,
      //       responseData: error?.response?.data,
      //     },
      //   },
      // });

      return handleErrorResponse(error, toast, dispatch);
    }
  );

  axios.interceptors.request.use(
    config => config,
    error => {
      return Promise.reject(error);
    }
  );
}

function setToken(token) {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${token ?? ''}`,
    Accept: `application/json`,
    // "user-version": process.env.REACT_APP_VERSION,
  };
}

const httpService = {
  get: axios.get,
  patch: axios.patch,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setToken,
  initializeInterceptors
};

export default httpService
