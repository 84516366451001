import { useSelector } from "react-redux";
import ChooseBodyType from "../../components/common/choose.body.type";
import ImageWithText from "../../components/common/image.with.text";
import ChooseSeries from "./components/choose.series";
import TintedPackage from "./components/tinted.package";
import DownloadSeries from "./components/download.series";
import { useEffect } from "react";
import scrollToTop from "../../helpers/scroll.to.top";

export default function CarTinted() {
    const { fourBodyTypeList } = useSelector((state) => state.common);

    useEffect(() => {
      scrollToTop()
    }, [])

    return (
      <article className="car-tinted-page w-100 flex flex-column items-center" style={{ gap: '50px' }}>
        <ImageWithText locateAt="car-tinted" />
        <ChooseSeries />
        <ChooseBodyType bodyTypeList={fourBodyTypeList} className="tinted" />
        <TintedPackage />
        <DownloadSeries />
      </article>
    );
  }
  