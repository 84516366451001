export default function CommonStep({ 
    no, 
    total, 
    title, 
    subtitle, 
    isSelecting,
    className, 
    style 
}) {
    return (
        <article className={`common-step ${isSelecting ? '--available' : ''} ${className ?? ''}`} style={style}>
            <section className="step-circle" style={{ background: isSelecting ? '#ff4b4b' : '#9c9fb3' }}>
                <section className="step-circle__inner">

                    <h5 className="--no">{no}</h5>
                </section>
            </section>
            <section className="step-text">
                <h5>{title}</h5>
                {subtitle && <p>{subtitle}</p>}
            </section>
        </article>
    );
}