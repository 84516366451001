import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api.service";

export const getProfile = createAsyncThunk('getProfile', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getProfile(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const updateProfile = createAsyncThunk('updateProfile', async (payload, thunkAPI) => {
  try {
    const response = await apiService.updateProfile(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getCommissionList = createAsyncThunk('getCommissionList', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getCommissionList(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const initialData = {
    profileInfo: {},
    commissionList: [],
    
    updateLoading: false,
    updateProfile: false,
};

const dataSlice = createSlice({
  name: "profile_commission",
  initialState: initialData,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    .addCase(getProfile.pending, (state) => {
      state.loading = true;
    })
    .addCase(getProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.profileInfo = action.payload.data;
    })
    .addCase(getProfile.rejected, (state) => {
      state.loading = false;
    })

    .addCase(updateProfile.pending, (state) => {
      state.updateLoading = true;
    })
    .addCase(updateProfile.fulfilled, (state, action) => {
      state.updateLoading = false;
    })
    .addCase(updateProfile.rejected, (state) => {
      state.updateLoading = false;
    })

    .addCase(getCommissionList.pending, (state) => {
      state.loading = true;
    })
    .addCase(getCommissionList.fulfilled, (state, action) => {
      state.loading = false;
      state.commissionList = action.payload.data.aaData
    })
    .addCase(getCommissionList.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const { 
} = dataSlice.actions;

export default dataSlice.reducer;

