import Button from "../../../components/element/button";

export default function AboutCTA() {
  const handleRedirectWhatsapp = () => {
    const phoneNumber = "60122334402";
    const message =
      "Hi, I have an enquiry about your services and would like some assistance.";
    const whatsappURL = `https://wa.me/`;

    window.open(
      whatsappURL + phoneNumber + "?text=" + encodeURIComponent(message)
    );
  };

  return (
    <article className="w-100 grid center">
      <section className="about-cta">
        <article className="cta-text">
          <h4>Ready to Experience Excellence?</h4>
          <p>
            Discover how our expert services can protect and enhance your
            vehicle. Let's take your car care to the next level!
          </p>
          <Button
            btnClassName="subscribe-button"
            onClick={() => handleRedirectWhatsapp()}
          >
            Get in Touch Today
          </Button>
        </article>
      </section>
    </article>
  );
}
