import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/element/button";
import { Icon } from "@iconify/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useState } from "react";
import { addCart } from "../../../redux/reducer/cartOrderReducer";
import { useToast } from "../../../components/common/common.toast";
import { Skeleton } from "primereact/skeleton";
import { openLoginModal } from "../../../redux/reducer/modalReducer";
import { getCartCount } from "../../../redux/reducer/commonReducer";
import { DefaultJustint } from "../../../assets/images";

export default function PpfPackage() {
    const toast = useToast()
    const dispatch = useDispatch()

    const { ppfPackageList, productLoading } = useSelector((state) => state.service);
    const { selectedBodyType, selectedPackageOrAlacarte, token, username } = useSelector((state) => state.common);
    const { addCartLoading } = useSelector((state) => state.cart_order);

    const [ packageList, setPackageList ] = useState([])
    const [ packageId, setPackageId ] = useState('')

    const handleAddCart = (id) => {
        if(token && username) {    
            dispatch(addCart({
                product_id: [id],
                quantity: 1
            }))
            .unwrap()
            .then((res) => {
                dispatch(getCartCount())
                toast.success('Add to cart successfully.')
            })
            .catch((ex) => {
                setPackageId('')
                if (ex && ex.response?.status === 422) {
                    const errors = ex.response.data.errors;
                    if (errors && Object.keys(errors).length > 0) {
                        Object.keys(errors).map((item, i) => {
                            toast.error(errors[item][0]);
                        });
                    }
                }
            })
        } else {
            dispatch(openLoginModal())
        }
    }

    useEffect(() => {
        if(ppfPackageList[selectedBodyType]) {
           setPackageList(ppfPackageList[selectedBodyType])

        //    if(packageList.length > 0) {
        //        setPackageId(packageList[0]?.id)
        //    }
        } else {
           setPackageList([])
        }
    }, [ppfPackageList, selectedBodyType])


    useEffect(() => {
        setPackageId('')
    }, [selectedPackageOrAlacarte])

    return (
        <>
            {selectedPackageOrAlacarte === 'package' && <section className="ppf-package">
                <Swiper 
                    className="package-list"
                    slidesPerView="auto"
                    spaceBetween={20}
                    grabCursor={true}
                >
                    {!productLoading ? 
                        packageList.length > 0 && packageList.map((ppf, ppfIndex) => (
                            <SwiperSlide key={ppfIndex} className={`package-item ${packageId === ppf.id ? '--active' : ''}`} onClick={() => setPackageId(ppf.id)}>
                                <section className="package-detail">
                                    <article>
                                        <section className="package-frame">
                                            <LazyLoadImage
                                                src={ppf.media?.length > 0 ? ppf.media[0].url : DefaultJustint}
                                                alt={ppf.name?.name}
                                            />
                                        </section>
                                        <section className="package-text">
                                            <h6>{ppf.name?.name}</h6>
                                            <article className="feature-list">
                                                {ppf.name?.package_items?.length > 0 && ppf.name.package_items.map((feature, featureIndex) => (
                                                    <article key={featureIndex} className="flex items-center gap-2">
                                                        <article>
                                                            <Icon icon="mdi:tick-decagram" />
                                                        </article>
                                                        <p>{feature}</p>
                                                    </article>
                                                ))}
                                            </article>
                                            <article className="free-list">
                                                {ppf.name?.description?.length > 0 && ppf.name.description.map((free, freeIndex) => (
                                                    <p key={freeIndex} className="--free">{free}</p>
                                                ))}
                                            </article>
                                        </section>
                                    </article>
                                    {!isNaN(ppf.price) && <h6 className="--price">RM 
                                        {parseFloat(ppf.price).toLocaleString()}
                                    </h6>}
                                </section>
                                <Button btnClassName={`add-cart-button ppf ${packageId === ppf.id ? '' : '--none'}`} disabled={addCartLoading && (ppf.id === packageId)} onClick={() => handleAddCart(ppf.id)}>
                                    {!isNaN(ppf.price) ? 'Add to Cart' : 'View Price'}
                                </Button>
                            </SwiperSlide>
                        ))
                    : 
                        Array.from({ length: 4 }, (_, ppfSkeletonIndex) => (
                            <SwiperSlide key={ppfSkeletonIndex} className="package-item" style={{ height: '468px' }}>
                                <Skeleton
                                    width="100%"
                                    height="100%"
                                    className="package-detail"
                                />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </section>}
        </>
    );
}