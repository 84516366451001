import { useSelector } from "react-redux";
import CartBody from "./components/cart.body";
import CartHeader from "./components/cart.header";
import EditPriceModal from "./modals/edit.price.modal";
import AccessoriesModal from "../../components/common/accessories.modal";
import scrollToTop from "../../helpers/scroll.to.top";
import { useEffect } from "react";

export default function Cart() {
    const { isOpenEditPriceModal, isOpenAccessoriesModal } = useSelector((state) => state.modal);

    useEffect(() => {
      scrollToTop()
    }, [])

    return (
      <section className="cart-page page-padding">
        <CartHeader />
        <CartBody />
        {isOpenEditPriceModal && <EditPriceModal />}
        {isOpenAccessoriesModal && <AccessoriesModal />}
      </section>
    );
  }
  