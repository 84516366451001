import { useDispatch, useSelector } from "react-redux";
import Footer from "../footer/footer";
import MainHeader from "../header/main.header";
import useWindowDimensions from "../hook/use.window.dimensions";
import LoginModal from "../../pages/login/modals/login.modal";
import { useEffect } from "react";
import {
  getCartCount,
  getToken,
  getUsername,
  getUserRank,
  getUserRole,
} from "../../redux/reducer/commonReducer";
import scrollToTop from "../../helpers/scroll.to.top";
import Preloader from "../common/preloader";

export default function MainLayout(props) {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { token, username } = useSelector((state) => state.common);
  const { isOpenMenuTab, isOpenLoginModal } = useSelector(
    (state) => state.modal
  );

  // if unused, then can remove
  // body will set to unscrollable when loading

  //   useEffect(() => {
  //     if (example_loading) {
  //       scrollToTop()
  //       document.body.style.overflow = 'hidden'
  //     } else {
  //       document.body.style.overflow = 'auto'
  //     }
  //   }, [exapmle_loading]);

  useEffect(() => {
    dispatch(getToken());
    dispatch(getUsername());
    dispatch(getUserRank());
    dispatch(getUserRole());
  }, []);

  useEffect(() => {
    if (token && username) {
      dispatch(getCartCount());
    }
  }, [token, username]);

  return (
    <main className="overflow-hidden">
      <Preloader />
      {isOpenMenuTab && <article className="common-overlay" />}
      <MainHeader />
      <article className="content-container">
        {props.children}
        {isOpenLoginModal && <LoginModal />}
      </article>
      <Footer />
    </main>
  );
}
