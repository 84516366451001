import { LazyLoadImage } from "react-lazy-load-image-component";
import { WhyJustintImg } from "../../../assets/images";
import CommonText from "../../../components/common/common.text";
import { useRef } from "react";
import { motion, useInView } from "framer-motion";

export default function WhyJustint() {
    const whyJustintRef = useRef(null);
    const isInView = useInView(whyJustintRef, { once: true, amount: 0.1 });

    return (
        <article className="w-100 flex flex-column items-center" style={{ gap: '50px' }}>
            <motion.section 
                // ref={whyJustintRef}
                // initial="hidden"
                // animate={isInView ? "visible" : "hidden"}
                // transition={{ duration: 0.5 }}
                className="why-justint"
            >
                <motion.article 
                    // variants={{
                    //     hidden: { opacity: 0, x: -100 }, 
                    //     visible: { opacity: 1, x: 0 }
                    // }}
                    // transition={{ duration: 0.5 }}
                    className="why-frame"
                >
                    <LazyLoadImage
                        src={WhyJustintImg}
                        alt="why-choose"
                    />
                </motion.article>
                <motion.article 
                    // variants={{
                    //     hidden: { opacity: 0, x: 100 },
                    //     visible: { opacity: 1, x: 0 }
                    // }}
                    // transition={{ duration: 0.5, delay: 0.2 }}
                    className="why-text"
                >
                    <CommonText locateAt='why-justint' />
                </motion.article>
            </motion.section>
        </article>
    );
}