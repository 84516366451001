import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";

export default function WarrantyCoverage() {
    const { warrantyList } = useSelector((state) => state.service);

    return (
        <section className="w-100 flex flex-column items-center" style={{ background: '#131417' }}>
            <article className="warranty-coverage">
                <section className="warranty-text">
                    <h3>Jus-Tint PPF Warranty Coverage</h3>
                    <p className="--grey">Jus-tint window films deliver comfort and protection to you and your family at all times</p>
                    <p>During the written warranty period, in the event a defect malfunction or other failure of the products covered not caused by negligence, misuse or any other cause other than normal use and operation, Jus-tint will replace or repair any warranted part without charge for the part to the customer.</p>
                </section>
                <section className="warranty-list">
                    {warrantyList.map((warranty, warrantyIndex) => (
                        <article className="warranty-item" key={warrantyIndex}>
                            <article className="warranty-frame">
                                <LazyLoadImage 
                                    src={warranty.image} 
                                    alt={warranty.label} 
                                />
                            </article>  
                            <h6>{warranty.label}</h6>
                        </article>
                    ))}
                </section>
                <p className="">Please noted that watermarks, deep scratches and yellowing (especially discoloration on white color vehicles) are not covered by the warranty.</p>
            </article>
        </section>
    );
}