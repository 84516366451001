import { useSelector } from "react-redux";
import AccessoriesModal from "../../components/common/accessories.modal";
import AccessoriesBody from "./components/accessories.body";
import AccessoriesHeader from "./components/accessories.header";
import scrollToTop from "../../helpers/scroll.to.top";
import { useEffect } from "react";

export default function Accessories() {
  const { isOpenAccessoriesModal } = useSelector((state) => state.modal);  
    
  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <article className="accessories-page w-100 flex flex-column items-center" style={{ gap: '35px' }}>
      <AccessoriesHeader />
      <AccessoriesBody />
      {isOpenAccessoriesModal && <AccessoriesModal />}
    </article>
  );
}
  