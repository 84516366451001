import { useState } from "react";

export default function WhatIsWindscreen() {
    const coverageList = [
        'Front windscreen',
        'Back or rear windscreens',
        'All door windows and quarter glass',
        'Sunroof glass (origin not a modification sunroof)'
    ]

    return (
      <article className="what-is-windscreen">
        <h3>What is Windscreen Insurance Coverage?</h3>
        <section className="windscreen-coverage">
            <p>Windscreen insurance or coverage is a type of additional protection for your car's windscreen. It is an add-on to your car insurance policy, you have to activate your car's insurance to be able to purchase this windscreen insurance.</p>
            <br />
            <br />
            <p>The coverage <span>provides protection, repair or direct replacement</span> for:</p>
            <br />
            <br />
            <ol className="coverage-list">
                {coverageList.map((coverage, coverageIndex) => (
                    <li key={coverageIndex}>&nbsp;{coverage}</li>
                ))}
            </ol>
        </section>
        <section className="coverage-bg" />
      </article>
    );
}
  