import { useSelector } from "react-redux";
import ChooseBodyType from "../../components/common/choose.body.type";
import ImageWithText from "../../components/common/image.with.text";
import CoatingAlacarte from "./components/coating.alacarte";
import CoatingPackage from "./components/coating.package";
import scrollToTop from "../../helpers/scroll.to.top";
import { useEffect } from "react";
import CoatingDescription from "./components/coating.description";

export default function CarCoating() {
    const { threeBodyTypeList } = useSelector((state) => state.common);

    useEffect(() => {
      scrollToTop()
    }, [])

    return (
      <article className="car-coating-page w-100" style={{ gap: '50px' }}>
        <CoatingDescription />
        <ChooseBodyType bodyTypeList={threeBodyTypeList} />
        <CoatingAlacarte />
        <CoatingPackage />
        <ImageWithText locateAt='car-coating-bottom' />
      </article>
    );
  }
  