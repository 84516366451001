import { useDispatch } from "react-redux";
import Button from "../element/button";
import { openLoginModal } from "../../redux/reducer/modalReducer";

export default function CommonViewPrice({ className }) {
    const dispatch = useDispatch()

    const handleLogin = () => {
        dispatch(openLoginModal())
    }

    return (
        <Button 
            className={`w-100 flex justify-center ${className ?? ''}`} 
            btnClassName="total-button view-price"
            onClick={() => handleLogin()}
        >
            <>
                <h5>View Price</h5>
                <article className="total-divider"/>
                <p>Click Here</p>
            </>
        </Button>
    );
}